export const PAGE_LOGIN_PATH = '/';
export const PAGE_HOME_PATH = '/home';
export const PAGE_USERS_PATH = '/users';
export const PAGE_ADD_USER_PATH = '/add-user';
export const PAGE_EDIT_USER_PATH = '/edit-user/:id';
export const PAGE_DOCUMENTS_PATH = '/documents';
export const PAGE_ADS_PATH = '/ads';
export const PAGE_ADD_AD_PATH = '/add-ad';
export const PAGE_EDIT_AD_PATH = '/edit-ad/:id';
export const PAGE_SUPPORT_PATH = '/support';
