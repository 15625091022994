interface CheckedProps {
  color: string;
}

const CheckboxCheckedIcon = ({ color }: CheckedProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" stroke={color} />
    <path
      d="M5.38452 9.99987L8.84606 13.4614L14.6153 6.53833"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckboxCheckedIcon;
