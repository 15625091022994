import { SiderItemIconComponentProps } from '../../types/sider-items';

const DocumentsListIcon = ({ color }: SiderItemIconComponentProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9758 2.12046H12.6264V0.771092C12.6264 0.346968 12.2794 0 11.8555 0H2.02399C1.5999 0 1.25293 0.346968 1.25293 0.771092V13.1084C1.25293 13.5325 1.5999 13.8795 2.02402 13.8795H3.37343V15.2289C3.37343 15.653 3.72024 16 4.14452 16H10.6985C11.1224 16 11.7224 15.7621 12.031 15.4716L14.185 13.4441C14.494 13.1536 14.7465 12.5687 14.7465 12.1446L14.7469 2.89162C14.7469 2.46746 14.3999 2.12046 13.9758 2.12046ZM3.37336 2.89159V13.3012H2.02399C1.91952 13.3012 1.83124 13.2129 1.83124 13.1084V0.771092C1.83124 0.666624 1.91952 0.578343 2.02399 0.578343H11.8555C11.9598 0.578343 12.0483 0.666624 12.0483 0.771092V2.1205H4.14446C3.72018 2.1205 3.37336 2.46746 3.37336 2.89159ZM13.7886 13.023L11.6346 15.0504C11.5905 15.0919 11.533 15.1325 11.4696 15.1713V13.3976C11.4696 13.2651 11.523 13.146 11.6111 13.0573C11.6999 12.9692 11.8191 12.9157 11.9515 12.9157H13.8802C13.8503 12.9563 13.8197 12.9937 13.7886 13.023ZM14.1682 12.1445C14.1682 12.2018 14.1577 12.2681 14.1419 12.3373H11.9515C11.3664 12.3384 10.8924 12.8125 10.8912 13.3975V15.3983C10.8224 15.4122 10.7561 15.4216 10.6985 15.4216H4.14446V15.4216C4.03977 15.4216 3.95171 15.3333 3.95171 15.2289V2.89156C3.95171 2.78709 4.0398 2.69881 4.14446 2.69881H13.9758C14.0802 2.69881 14.1685 2.78709 14.1685 2.89156L14.1682 12.1445Z"
      fill={color}
    />
    <path
      d="M11.8556 4.81665H6.26523C6.10542 4.81665 5.97607 4.94599 5.97607 5.10581C5.97607 5.26562 6.10545 5.39496 6.26523 5.39496H11.8556C12.0152 5.39496 12.1447 5.26562 12.1447 5.10581C12.1447 4.94599 12.0152 4.81665 11.8556 4.81665Z"
      fill={color}
    />
    <path
      d="M11.8556 8.67578H6.26523C6.10542 8.67578 5.97607 8.80512 5.97607 8.96494C5.97607 9.12475 6.10545 9.25409 6.26523 9.25409H11.8556C12.0152 9.25409 12.1447 9.12475 12.1447 8.96494C12.1447 8.80509 12.0152 8.67578 11.8556 8.67578Z"
      fill={color}
    />
    <path
      d="M9.34954 10.6018H6.26523C6.10542 10.6018 5.97607 10.7311 5.97607 10.891C5.97607 11.0508 6.10542 11.1801 6.26523 11.1801H9.34954C9.50916 11.1801 9.63851 11.0508 9.63851 10.891C9.63851 10.7311 9.50916 10.6018 9.34954 10.6018Z"
      fill={color}
    />
    <path
      d="M11.8556 6.74976H6.26523C6.10542 6.74976 5.97607 6.8791 5.97607 7.03891C5.97607 7.19872 6.10545 7.32807 6.26523 7.32807H11.8556C12.0152 7.32807 12.1447 7.19872 12.1447 7.03891C12.1447 6.8791 12.0152 6.74976 11.8556 6.74976Z"
      fill={color}
    />
  </svg>
);

export default DocumentsListIcon;
