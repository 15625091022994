import { AdAction, AdActionTypes, AdState } from '../types/ad';

const initialState: AdState = {
  ads: [],
  propertyTypes: [],
  novelty: [],
  totalPages: 0,
  loading: false,
  error: null,
};

export const adReducer = (state = initialState, action: AdAction): AdState => {
  switch (action.type) {
    case AdActionTypes.FETCH_ADS:
      return { ...state, loading: true };
    case AdActionTypes.FETCH_ADS_SUCCESS:
      return {
        ...state,
        loading: false,
        ads: action.payload.ads,
        totalPages: action.payload.totalPages,
      };
    case AdActionTypes.FETCH_ADS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        ads: [],
        totalPages: 0,
      };
    case AdActionTypes.FETCH_PROPERTY_TYPES:
      return { ...state, loading: true };
    case AdActionTypes.FETCH_PROPERTY_TYPES_SUCCESS:
      return { ...state, loading: false, propertyTypes: action.payload };
    case AdActionTypes.FETCH_PROPERTY_TYPES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        propertyTypes: [],
      };
    case AdActionTypes.FETCH_NOVELTY:
      return { ...state, loading: true };
    case AdActionTypes.FETCH_NOVELTY_SUCCESS:
      return { ...state, loading: false, novelty: action.payload };
    case AdActionTypes.FETCH_NOVELTY_ERROR:
      return { ...state, loading: false, error: action.payload, novelty: [] };
    case AdActionTypes.CREATE_AD:
      return { ...state, loading: true, error: null };
    case AdActionTypes.CREATE_AD_SUCCESS:
      return { ...state, loading: false, error: null };
    case AdActionTypes.CREATE_AD_ERROR:
      return { ...state, loading: false, error: action.payload };
    case AdActionTypes.EDIT_AD:
      return { ...state, loading: true, error: null };
    case AdActionTypes.EDIT_AD_SUCCESS:
      return { ...state, loading: false, error: null };
    case AdActionTypes.EDIT_AD_ERROR:
      return { ...state, loading: false, error: action.payload };
    case AdActionTypes.DELETE_AD:
      return { ...state, loading: true, error: null };
    case AdActionTypes.DELETE_AD_SUCCESS:
      return { ...state, loading: false, error: null };
    case AdActionTypes.DELETE_AD_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
