import { Input as AntInput } from 'antd';
import styled from 'styled-components';

const InputText: React.FC<InputProps> = ({ ...props }) => (
  <StyledInput {...props} />
);

interface InputProps {
  value?: string | undefined;
  type?: 'text' | 'email' | 'password';
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
  name?: string;
}

const StyledInput = styled(AntInput)`
  height: 51px;
  border-radius: ${(props) => props.theme.borderRadius.big};
  border: 1px solid ${(props) => props.theme.colors.color4};
  color: ${(props) => props.theme.colors.color2};
`;

export default InputText;
