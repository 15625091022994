import { SiderItemIconComponentProps } from '../../types/sider-items';

const HomeIcon = ({ color }: SiderItemIconComponentProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6 6.92799C15.5995 6.92763 15.5992 6.92714 15.5988 6.92677L9.07206 0.400269C8.79386 0.121949 8.42398 -0.03125 8.03055 -0.03125C7.63712 -0.03125 7.26724 0.121949 6.98892 0.400269L0.465594 6.92348C0.463396 6.92567 0.461077 6.92799 0.459002 6.93019C-0.112289 7.50478 -0.111312 8.43703 0.461809 9.01015C0.723651 9.27211 1.06935 9.42372 1.43911 9.43972C1.45424 9.44118 1.46938 9.44191 1.48464 9.44191H1.74465V14.2449C1.74465 15.1955 2.51809 15.9688 3.46865 15.9688H6.02212C6.28103 15.9688 6.49087 15.7588 6.49087 15.5V11.7344C6.49087 11.3007 6.84378 10.9479 7.2775 10.9479H8.7836C9.21732 10.9479 9.5701 11.3007 9.5701 11.7344V15.5C9.5701 15.7588 9.77994 15.9688 10.0389 15.9688H12.5923C13.543 15.9688 14.3163 15.1955 14.3163 14.2449V9.44191H14.5575C14.9509 9.44191 15.3207 9.28871 15.5992 9.01027C16.1729 8.4363 16.1731 7.50233 15.6 6.92799ZM14.9362 8.34743C14.835 8.44862 14.7005 8.50441 14.5575 8.50441H13.8476C13.5887 8.50441 13.3788 8.71425 13.3788 8.97316V14.2449C13.3788 14.6785 13.026 15.0313 12.5923 15.0313H10.5076V11.7344C10.5076 10.7838 9.73429 10.0104 8.7836 10.0104H7.2775C6.32681 10.0104 5.55337 10.7838 5.55337 11.7344V15.0313H3.46865C3.03506 15.0313 2.68215 14.6785 2.68215 14.2449V8.97316C2.68215 8.71425 2.47231 8.50441 2.2134 8.50441H1.51564C1.50832 8.50392 1.50112 8.50356 1.49367 8.50343C1.35402 8.50099 1.22304 8.44557 1.1249 8.34731C0.916156 8.13857 0.916156 7.79884 1.1249 7.58998C1.12502 7.58998 1.12502 7.58986 1.12514 7.58974L1.12551 7.58937L7.65201 1.06311C7.75308 0.961916 7.88748 0.906252 8.03055 0.906252C8.17349 0.906252 8.30789 0.961916 8.40909 1.06311L14.9341 7.58803C14.9351 7.589 14.9362 7.58998 14.9372 7.59096C15.1448 7.80006 15.1445 8.13905 14.9362 8.34743Z"
      fill={color}
    />
  </svg>
);

export default HomeIcon;
