import {
  AdsListIcon,
  DocumentsListIcon,
  HomeIcon,
  SupportIcon,
  UsersListIcon,
} from '../assets/icons';
import { SiderItemIconComponentProps } from '../types/sider-items';

interface ISiderItems {
  id: number;
  name: string;
  url: string;
  icon: React.FC<SiderItemIconComponentProps>;
}

export const siderItems: ISiderItems[] = [
  {
    id: 0,
    name: 'Home',
    url: '/home',
    icon: HomeIcon,
  },
  {
    id: 1,
    name: 'List of users',
    url: '/users',
    icon: UsersListIcon,
  },
  {
    id: 2,
    name: 'List of documents',
    url: '/documents',
    icon: DocumentsListIcon,
  },
  {
    id: 3,
    name: 'List of ads',
    url: '/ads',
    icon: AdsListIcon,
  },
  {
    id: 4,
    name: 'Support',
    url: '/support',
    icon: SupportIcon,
  },
];
