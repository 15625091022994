import { Form, Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

import FormItem from '../../../components/FormItem';
import { Button, Flex, InputNumber, Spinner, Text } from '../../../ui';

const ApproveModal: React.FC<ApproveModalProps> = ({
  isVisible,
  onCancel,
  taxValue,
  onTaxValueChange,
  additionalCharges,
  onAdditionalChargesValueChange,
  onApprove,
  loading,
}) => {
  return (
    <StyledModal visible={isVisible} onCancel={onCancel} footer={null}>
      <Text variant="title1bold">Please enter the following amounts</Text>
      <Flex mt="30px">
        <Form name="basic" layout={'vertical'} onFinish={onApprove}>
          <FormItem
            name={'salesTax'}
            label={'Sales Tax'}
            rules={[
              {
                required: true,
                message: 'Incorrect tax field',
                type: 'number',
              },
            ]}
          >
            <InputNumber value={taxValue} onChange={onTaxValueChange} />
          </FormItem>
          <FormItem
            name={'additionalCharges'}
            label={
              'Additional Charges in accordance with the Purchase Agreement'
            }
            rules={[
              {
                required: true,
                message: 'Incorrect charges field',
                type: 'number',
              },
            ]}
          >
            <InputNumber
              value={additionalCharges}
              onChange={onAdditionalChargesValueChange}
            />
          </FormItem>
          <Flex mt="30px">
            <Button
              width="100%"
              variant="primary"
              text={'ENTER'}
              disabled={loading}
            >
              <Spinner isRequesting={loading} variant={'small'} />
            </Button>
          </Flex>
        </Form>
      </Flex>
    </StyledModal>
  );
};

interface ApproveModalProps {
  isVisible: boolean;
  onCancel: () => void;
  taxValue: number;
  onTaxValueChange: (value: string | number) => void;
  additionalCharges: number;
  onAdditionalChargesValueChange: (value: string | number) => void;
  onApprove: () => void;
  loading: boolean;
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 440px;
  }
  .ant-modal-body {
    padding: 30px;
  }
`;

export default ApproveModal;
