import styled from 'styled-components';

import { UploadIcon } from '../../../../assets/icons';
import { Flex, Text } from '../../../../ui';

interface UploadItemProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadItem: React.FC<UploadItemProps> = ({ onChange }) => {
  return (
    <Root>
      <HiddenInputWrapper>
        <input
          type="file"
          name="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={onChange}
        />
      </HiddenInputWrapper>
      <Text variant="footnote1" color="color4">
        Drag photo
      </Text>
      <Text variant="footnote1" color="color4">
        or
      </Text>
      <Flex flexDirection="row" alignItems="center" cursor="pointer">
        <Flex mr="4px">
          <UploadIcon />
        </Flex>
        <div style={{ whiteSpace: 'nowrap' }}>
          <Text variant="footnote1" color="color0">
            Upload picture
          </Text>
        </div>
      </Flex>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  height: 150px;
  padding: 36px;
  border: 1px dashed ${(props) => props.theme.colors.color4};
  border-radius: ${(props) => props.theme.borderRadius.small};
`;

const HiddenInputWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;

export default UploadItem;
