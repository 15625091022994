import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import LoginPage from '../pages/Login';
import { store } from '../store';
import { lightTheme } from '../themes/light';
import AdminPanel from './AdminPanel';
import AuthRoute from './AuthRoute';
import { PAGE_LOGIN_PATH } from './constants';

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <Router>
          <Switch>
            <AuthRoute exact path={PAGE_LOGIN_PATH} type="guest">
              <LoginPage />
            </AuthRoute>
            <AdminPanel />
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
