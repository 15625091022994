import styled from 'styled-components';

import { SearchIcon } from '../../assets/icons';

const Search = () => {
  return (
    <Root>
      <Input type="text" placeholder="Search" />
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 51px;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 15px;
  border: 0;
  outline: 0;
  border-radius: ${(props) => props.theme.borderRadius.big};

  &::placeholder {
    font-size: ${(props) => props.theme.fontSize.medium};
    color: ${(props) => props.theme.colors.color4};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;

export default Search;
