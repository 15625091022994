export type TUserRole = 'ADMIN' | 'USER' | 'MODERATOR' | 'SUPPORT';

export interface IRole {
  id: number;
  name: TUserRole;
}

export interface IUsers {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  citizenship: string | null;
  roles: IRole[];
}

export interface UserState {
  users: IUsers[];
  totalPages: number;
  loading: boolean;
  error: null | string;
}

export enum UserActionTypes {
  FETCH_USERS = 'FETCH_USERS',
  FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS',
  FETCH_USERS_ERROR = 'FETCH_USERS_ERROR',
  CREATE_USER = 'CREATE_USER',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR = 'CREATE_USER_ERROR',
  EDIT_USER = 'EDIT_USER',
  EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
  EDIT_USER_ERROR = 'EDIT_USER_ERROR',
}

interface FetchUsersAction {
  type: UserActionTypes.FETCH_USERS;
}

interface FetchUsersSuccessAction {
  type: UserActionTypes.FETCH_USERS_SUCCESS;
  payload: {
    users: IUsers[];
    totalPages: number;
  };
}

interface FetchUsersErrorAction {
  type: UserActionTypes.FETCH_USERS_ERROR;
  payload: string;
}

interface CreateUserAction {
  type: UserActionTypes.CREATE_USER;
}

interface CreateUserSuccessAction {
  type: UserActionTypes.CREATE_USER_SUCCESS;
}

interface CreateUserErrorAction {
  type: UserActionTypes.CREATE_USER_ERROR;
  payload: string;
}

interface EditUserAction {
  type: UserActionTypes.EDIT_USER;
}

interface EditUserSuccessAction {
  type: UserActionTypes.EDIT_USER_SUCCESS;
}

interface EditUserErrorAction {
  type: UserActionTypes.EDIT_USER_ERROR;
  payload: string;
}

export type UserAction =
  | FetchUsersAction
  | FetchUsersSuccessAction
  | FetchUsersErrorAction
  | CreateUserAction
  | CreateUserSuccessAction
  | CreateUserErrorAction
  | EditUserAction
  | EditUserSuccessAction
  | EditUserErrorAction;
