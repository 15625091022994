interface IPropertyType {
  id: number;
  name: string;
}
interface INovelty {
  id: number;
  name: string;
}
export interface IAdsImage {
  id: number;
  url: string;
}
interface ICity {
  id: number;
  name: string;
}
interface ICountry {
  id: number;
  name: string;
}
export interface IAds {
  id: number;
  bedrooms: number;
  bathrooms: number;
  floorSpace: number;
  price: number;
  capacity: number;
  address: string;
  propertyType: IPropertyType;
  novelty: INovelty;
  country: ICountry;
  city: ICity;
  longitude: number;
  latitude: number;
  name: string;
  tourUrl?: string;
  description: string;
  images: IAdsImage[];
  preview: IAdsImage;
  isFavorite: boolean;
}

export interface AdState {
  ads: IAds[];
  propertyTypes: IPropertyType[];
  novelty: INovelty[];
  totalPages: number;
  loading: boolean;
  error: null | string;
}

export enum AdActionTypes {
  FETCH_ADS = 'FETCH_ADS',
  FETCH_ADS_SUCCESS = 'FETCH_ADS_SUCCESS',
  FETCH_ADS_ERROR = 'FETCH_ADS_ERROR',
  FETCH_PROPERTY_TYPES = 'FETCH_PROPERTY_TYPES',
  FETCH_PROPERTY_TYPES_SUCCESS = 'FETCH_PROPERTY_TYPES_SUCCESS',
  FETCH_PROPERTY_TYPES_ERROR = 'FETCH_PROPERTY_TYPES_ERROR',
  FETCH_NOVELTY = 'FETCH_NOVELTY',
  FETCH_NOVELTY_SUCCESS = 'FETCH_NOVELTY_SUCCESS',
  FETCH_NOVELTY_ERROR = 'FETCH_NOVELTY_ERROR',
  CREATE_AD = 'CREATE_AD',
  CREATE_AD_SUCCESS = 'CREATE_AD_SUCCESS',
  CREATE_AD_ERROR = 'CREATE_AD_ERROR',
  EDIT_AD = 'EDIT_AD',
  EDIT_AD_SUCCESS = 'EDIT_AD_SUCCESS',
  EDIT_AD_ERROR = 'EDIT_AD_ERROR',
  DELETE_AD = 'DELETE_AD',
  DELETE_AD_SUCCESS = 'DELETE_AD_SUCCESS',
  DELETE_AD_ERROR = 'DELETE_AD_ERROR',
}

interface FetchAdsAction {
  type: AdActionTypes.FETCH_ADS;
}
interface FetchAdsSuccessAction {
  type: AdActionTypes.FETCH_ADS_SUCCESS;
  payload: {
    ads: IAds[];
    totalPages: number;
  };
}
interface FetchAdsErrorAction {
  type: AdActionTypes.FETCH_ADS_ERROR;
  payload: string;
}
interface FetchPropertyTypesAction {
  type: AdActionTypes.FETCH_PROPERTY_TYPES;
}
interface FetchPropertyTypesSuccessAction {
  type: AdActionTypes.FETCH_PROPERTY_TYPES_SUCCESS;
  payload: IPropertyType[];
}
interface FetchPropertyTypesErrorAction {
  type: AdActionTypes.FETCH_PROPERTY_TYPES_ERROR;
  payload: string;
}
interface FetchNoveltyAction {
  type: AdActionTypes.FETCH_NOVELTY;
}
interface FetchNoveltySuccessAction {
  type: AdActionTypes.FETCH_NOVELTY_SUCCESS;
  payload: INovelty[];
}
interface FetchNoveltyErrorAction {
  type: AdActionTypes.FETCH_NOVELTY_ERROR;
  payload: string;
}
interface CreateAdAction {
  type: AdActionTypes.CREATE_AD;
}
interface CreateAdSuccessAction {
  type: AdActionTypes.CREATE_AD_SUCCESS;
}
interface CreateAdErrorAction {
  type: AdActionTypes.CREATE_AD_ERROR;
  payload: string;
}
interface EditAdAction {
  type: AdActionTypes.EDIT_AD;
}
interface EditAdSuccessAction {
  type: AdActionTypes.EDIT_AD_SUCCESS;
}
interface EditAdErrorAction {
  type: AdActionTypes.EDIT_AD_ERROR;
  payload: string;
}
interface DeleteAdAction {
  type: AdActionTypes.DELETE_AD;
}
interface DeleteAdSuccessAction {
  type: AdActionTypes.DELETE_AD_SUCCESS;
}
interface DeleteAdErrorAction {
  type: AdActionTypes.DELETE_AD_ERROR;
  payload: string;
}
export type AdAction =
  | FetchAdsAction
  | FetchAdsSuccessAction
  | FetchAdsErrorAction
  | FetchPropertyTypesAction
  | FetchPropertyTypesSuccessAction
  | FetchPropertyTypesErrorAction
  | FetchNoveltyAction
  | FetchNoveltySuccessAction
  | FetchNoveltyErrorAction
  | CreateAdAction
  | CreateAdSuccessAction
  | CreateAdErrorAction
  | EditAdAction
  | EditAdSuccessAction
  | EditAdErrorAction
  | DeleteAdAction
  | DeleteAdSuccessAction
  | DeleteAdErrorAction;
