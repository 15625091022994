import { Form, Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

import FormItem from '../../../components/FormItem';
import { Button, Flex, Spinner, Text, TextArea } from '../../../ui';

const DenyModal: React.FC<DenyModalProps> = ({
  isVisible,
  onCancel,
  denyReasonValue,
  onChange,
  onDeny,
  loading,
}) => {
  return (
    <StyledModal visible={isVisible} onCancel={onCancel} footer={null}>
      <Text variant="title1bold">Enter reason</Text>
      <Flex mt="30px">
        <Form name="basic" layout={'vertical'} onFinish={onDeny}>
          <FormItem
            name="reason"
            label="Reason"
            rules={[
              {
                required: true,
                message: 'Incorrect reason!',
                type: 'string',
              },
            ]}
          >
            <TextArea value={denyReasonValue} onChange={onChange} />
          </FormItem>
          <Flex mt="30px">
            <Button
              width="100%"
              variant="primary"
              text={'ENTER'}
              disabled={loading}
            >
              <Spinner isRequesting={loading} variant={'small'} />
            </Button>
          </Flex>
        </Form>
      </Flex>
    </StyledModal>
  );
};

interface DenyModalProps {
  isVisible: boolean;
  onCancel: () => void;
  denyReasonValue: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onDeny: () => void;
  loading: boolean;
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 440px;
  }
  .ant-modal-body {
    padding: 30px;
  }
`;

export default DenyModal;
