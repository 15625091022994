import styled from 'styled-components';

import FormItem from '../../../components/FormItem/index';
import { Flex, InputNumber, Text } from '../../../ui';

interface ICharacteristic {
  id: number;
  name: string;
  value: number;
  onChange: (value: string | number) => void;
}

interface CharacteristicsProps {
  characteristics: ICharacteristic[];
}

const Characteristics: React.FC<CharacteristicsProps> = ({
  characteristics,
}) => {
  return (
    <div>
      <Text variant="title1bold" mb="30px">
        Main Characteristics
      </Text>
      <Wrapper>
        {characteristics.map((el: ICharacteristic) => (
          <Flex key={el.id} flexDirection="row" height="51px">
            <LabelWrapper>{el.name}</LabelWrapper>
            <Flex width="65px">
              <FormItem
                name={el.name}
                rules={[
                  {
                    required: true,
                    message: `Incorrect ${el.name}!`,
                    type: 'number',
                  },
                ]}
              >
                <InputNumber value={el.value} onChange={el.onChange} />
              </FormItem>
            </Flex>
          </Flex>
        ))}
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  grid-column-gap: 10%;
  width: 90%;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  width: calc(100% - 80px);
  margin-right: 15px;
  border: 1px solid ${(props) => props.theme.colors.color4};
  border-radius: ${(props) => props.theme.borderRadius.big};
  text-transform: capitalize;
`;

export default Characteristics;
