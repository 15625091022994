import { SiderItemIconComponentProps } from '../../types/sider-items';

const AdsListIcon = ({ color }: SiderItemIconComponentProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0008 2.49902H4.00429C3.4522 2.49902 3.00464 2.94658 3.00464 3.49867C3.00464 4.05076 3.4522 4.49832 4.00429 4.49832H14.0008C14.5529 4.49832 15.0004 4.05076 15.0004 3.49867C15.0004 2.94658 14.5529 2.49902 14.0008 2.49902Z"
      fill={color}
    />
    <path
      d="M2.0049 3.49886C2.00377 3.23417 1.89772 2.98073 1.71 2.7941C1.32011 2.40652 0.69039 2.40652 0.300496 2.7941C0.112749 2.98073 0.00669296 3.23417 0.00559959 3.49886C-0.00186653 3.56362 -0.00186653 3.62903 0.00559959 3.69379C0.0169081 3.75892 0.0353391 3.82262 0.0605803 3.88372C0.0874146 3.94292 0.119185 3.99977 0.155547 4.05366C0.19144 4.1097 0.233332 4.16166 0.280503 4.20861C0.325987 4.2539 0.376251 4.29414 0.43045 4.32857C0.483088 4.3669 0.540162 4.39879 0.60039 4.42353C0.666648 4.4579 0.73728 4.48314 0.810317 4.49851C0.875075 4.50575 0.94049 4.50575 1.00525 4.49851C1.26922 4.49872 1.52257 4.39451 1.71 4.20861C1.75717 4.16166 1.79906 4.1097 1.83496 4.05366C1.87132 3.99977 1.90309 3.94292 1.92992 3.88372C1.96182 3.82337 1.98697 3.75967 2.0049 3.69379C2.01236 3.62903 2.01236 3.56362 2.0049 3.49886Z"
      fill={color}
    />
    <path
      d="M2.00491 7.99715C2.01235 7.93239 2.01235 7.86697 2.00491 7.80222C1.98764 7.73771 1.96246 7.6756 1.92994 7.61728C1.9042 7.55596 1.87237 7.49736 1.83497 7.44234C1.80033 7.38693 1.75828 7.33647 1.71002 7.2924C1.32012 6.90481 0.690406 6.90481 0.300512 7.2924C0.112765 7.47902 0.0067086 7.73246 0.00561523 7.99715C0.00755205 8.12888 0.0329494 8.25918 0.0805889 8.38201C0.105643 8.44115 0.135757 8.498 0.170557 8.55195C0.208575 8.60628 0.252122 8.65654 0.300512 8.7019C0.344684 8.75007 0.395103 8.79212 0.450459 8.82686C0.503097 8.86522 0.560139 8.89711 0.620399 8.92182C0.681346 8.94747 0.745105 8.96593 0.810332 8.9768C0.874279 8.99114 0.939724 8.99786 1.00526 8.9968C1.07002 9.00426 1.13544 9.00426 1.2002 8.9968C1.2638 8.98589 1.3259 8.96743 1.38513 8.94182C1.44701 8.91726 1.50574 8.88537 1.56007 8.84685C1.61542 8.81211 1.66584 8.77006 1.71002 8.72189C1.75819 8.67772 1.80023 8.6273 1.83497 8.57195C1.8734 8.51934 1.90529 8.4623 1.92994 8.40201C1.96408 8.33565 1.98929 8.26505 2.00491 8.19208C2.0126 8.12732 2.0126 8.06191 2.00491 7.99715Z"
      fill={color}
    />
    <path
      d="M2.00497 12.4957C2.01238 12.4309 2.01238 12.3655 2.00497 12.3008C1.9877 12.2347 1.96252 12.1709 1.93 12.1108C1.90317 12.0516 1.8714 11.9948 1.83503 11.9409C1.8003 11.8855 1.75825 11.8351 1.71008 11.7909C1.32018 11.4033 0.690467 11.4033 0.300573 11.7909C0.252402 11.8351 0.210355 11.8855 0.175617 11.9409C0.139255 11.9948 0.107484 12.0516 0.0806502 12.1108C0.0547218 12.1717 0.0362595 12.2355 0.0256695 12.3008C0.0115807 12.3648 0.00489554 12.4301 0.00567651 12.4957C0.00680112 12.7604 0.112858 13.0138 0.300573 13.2004C0.344745 13.2486 0.395165 13.2907 0.45052 13.3254C0.503158 13.3638 0.5602 13.3957 0.620461 13.4204C0.681408 13.446 0.745167 13.4645 0.810394 13.4753C0.87434 13.4897 0.939786 13.4964 1.00533 13.4953C1.07008 13.5028 1.1355 13.5028 1.20026 13.4953C1.26386 13.4844 1.32596 13.466 1.38519 13.4404C1.44708 13.4158 1.50581 13.3839 1.56013 13.3454C1.61549 13.3106 1.66591 13.2686 1.71008 13.2204C1.75825 13.1763 1.8003 13.1258 1.83503 13.0705C1.87349 13.0179 1.90538 12.9608 1.93 12.9005C1.96411 12.8342 1.98932 12.7636 2.00497 12.6906C2.01266 12.6259 2.01266 12.5604 2.00497 12.4957Z"
      fill={color}
    />
    <path
      d="M15.0004 6.99756H4.00429C3.4522 6.99756 3.00464 7.44512 3.00464 7.99721C3.00464 8.54929 3.4522 8.99686 4.00429 8.99686H15.0004C15.5525 8.99686 16.0001 8.54929 16.0001 7.99721C16.0001 7.44512 15.5525 6.99756 15.0004 6.99756Z"
      fill={color}
    />
    <path
      d="M10.502 11.4958H4.00429C3.4522 11.4958 3.00464 11.9434 3.00464 12.4955C3.00464 13.0476 3.4522 13.4951 4.00429 13.4951H10.502C11.0541 13.4951 11.5017 13.0476 11.5017 12.4955C11.5017 11.9434 11.0541 11.4958 10.502 11.4958Z"
      fill={color}
    />
  </svg>
);

export default AdsListIcon;
