export interface IDocument {
  id: number;
  name: string;
  url: string;
  isVerified: boolean;
}
export interface IDocumentRequest {
  id: number;
  documents: IDocument[];
  status: {
    id: number;
    name: string;
  };
}
export interface DocumentState {
  requests: IDocumentRequest[];
  totalPages: number;
  loading: boolean;
  error: null | string;
}
export enum DocumentActionTypes {
  FETCH_DOCUMENTS = 'FETCH_DOCUMENTS',
  FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS',
  FETCH_DOCUMENTS_ERROR = 'FETCH_DOCUMENTS_ERROR',
  DENY_DOCUMENT_REQUEST = 'DENY_DOCUMENT_REQUEST',
  DENY_DOCUMENT_REQUEST_SUCCESS = 'DENY_DOCUMENT_REQUEST_SUCCESS',
  DENY_DOCUMENT_REQUEST_ERROR = 'DENY_DOCUMENT_REQUEST_ERROR',
  APPROVE_DOCUMENT_REQUEST = 'APPROVE_DOCUMENT_REQUEST',
  APPROVE_DOCUMENT_REQUEST_SUCCESS = 'APPROVE_DOCUMENT_REQUEST_SUCCESS',
  APPROVE_DOCUMENT_REQUEST_ERROR = 'APPROVE_DOCUMENT_REQUEST_ERROR',
  DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT',
  DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS',
  DOWNLOAD_DOCUMENT_ERROR = 'DOWNLOAD_DOCUMENT_ERROR',
}
interface FetchDocumentsAction {
  type: DocumentActionTypes.FETCH_DOCUMENTS;
}
interface FetchDocumentsSuccessAction {
  type: DocumentActionTypes.FETCH_DOCUMENTS_SUCCESS;
  payload: {
    requests: IDocumentRequest[];
    totalPages: number;
  };
}
interface FetchDocumentsErrorAction {
  type: DocumentActionTypes.FETCH_DOCUMENTS_ERROR;
  payload: string;
}
interface DenyDocumentRequestAction {
  type: DocumentActionTypes.DENY_DOCUMENT_REQUEST;
}
interface DenyDocumentRequestSuccessAction {
  type: DocumentActionTypes.DENY_DOCUMENT_REQUEST_SUCCESS;
}
interface DenyDocumentRequestErrorAction {
  type: DocumentActionTypes.DENY_DOCUMENT_REQUEST_ERROR;
  payload: string;
}
interface ApproveDocumentRequestAction {
  type: DocumentActionTypes.APPROVE_DOCUMENT_REQUEST;
}
interface ApproveDocumentRequestSuccessAction {
  type: DocumentActionTypes.APPROVE_DOCUMENT_REQUEST_SUCCESS;
}
interface ApproveDocumentRequestErrorAction {
  type: DocumentActionTypes.APPROVE_DOCUMENT_REQUEST_ERROR;
  payload: string;
}
interface DownloadDocumentAction {
  type: DocumentActionTypes.DOWNLOAD_DOCUMENT;
}
interface DownloadDocumentSuccessAction {
  type: DocumentActionTypes.DOWNLOAD_DOCUMENT_SUCCESS;
}
interface DownloadDocumentErrorAction {
  type: DocumentActionTypes.DOWNLOAD_DOCUMENT_ERROR;
  payload: string;
}
export type DocumentAction =
  | FetchDocumentsAction
  | FetchDocumentsSuccessAction
  | FetchDocumentsErrorAction
  | DenyDocumentRequestAction
  | DenyDocumentRequestSuccessAction
  | DenyDocumentRequestErrorAction
  | ApproveDocumentRequestAction
  | ApproveDocumentRequestSuccessAction
  | ApproveDocumentRequestErrorAction
  | DownloadDocumentAction
  | DownloadDocumentSuccessAction
  | DownloadDocumentErrorAction;
