import { Select as AntSelect } from 'antd';
import { SelectValue } from 'antd/lib/select';
import styled from 'styled-components';

const Select: React.FC<SelectProps> = ({ options, ...props }) => (
  <StyledSelect {...props}>
    {options &&
      options.map((el) => (
        <AntSelect.Option key={el.id} value={el.name}>
          {el.name}
        </AntSelect.Option>
      ))}
  </StyledSelect>
);

interface Option {
  id: number;
  name: string;
}

interface SelectProps {
  value?: string | undefined;
  placeholder?: string;
  onChange?: (value: SelectValue) => void;
  disabled?: boolean;
  defaultValue?: string;
  options?: Option[];
  notFoundContent?: string;
}

const StyledSelect = styled(AntSelect)`
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 51px;
    border-radius: 10px;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 50px;
  }
`;

export default Select;
