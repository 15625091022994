import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import saorsa_logo3_svg from '../../assets/img/saorsa_logo3.svg';
import { siderItems } from '../../constants/siderItems';
import SiderItem from './SiderItem';

const { Sider } = Layout;

const CustomSider = () => {
  return (
    <StyledSider width={240}>
      <SaorsaLogoWrapper>
        <img alt="logo" src={saorsa_logo3_svg} className="saorsa-logo-img" />
      </SaorsaLogoWrapper>
      <SiderItemsList>
        {siderItems.map((item) => (
          <Link key={item.id} to={item.url}>
            <SiderItem name={item.name} IconComponent={item.icon} />
          </Link>
        ))}
      </SiderItemsList>
    </StyledSider>
  );
};

const StyledSider = styled(Sider)`
  max-width: 240px;
  width: 240px;
  min-height: 100vh;
  height: auto;
  background-color: ${(props) => props.theme.colors.color2};
`;

const SaorsaLogoWrapper = styled.div`
  padding: 30px;
`;

const SiderItemsList = styled.div`
  margin-top: 20px;
`;

export default CustomSider;
