const SearchIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7556 18.5776L14.0682 12.8901C15.1699 11.5293 15.8332 9.80015 15.8332 7.91685C15.8332 3.55189 12.2815 0.000244141 7.91656 0.000244141C3.55161 0.000244141 0 3.55185 0 7.91681C0 12.2818 3.55165 15.8334 7.9166 15.8334C9.7999 15.8334 11.5291 15.1701 12.8899 14.0684L18.5773 19.7559C18.7398 19.9184 18.9531 20 19.1665 20C19.3798 20 19.5932 19.9184 19.7557 19.7559C20.0815 19.4301 20.0815 18.9034 19.7556 18.5776ZM7.9166 14.1667C4.46996 14.1667 1.66666 11.3635 1.66666 7.91681C1.66666 4.47016 4.46996 1.66687 7.9166 1.66687C11.3632 1.66687 14.1665 4.47016 14.1665 7.91681C14.1665 11.3635 11.3632 14.1667 7.9166 14.1667Z"
      fill="#979797"
    />
  </svg>
);

export default SearchIcon;
