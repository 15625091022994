import { Form } from 'antd';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { SaorsaLogoIcon1 } from '../assets/icons';
import login_page_background_img from '../assets/img/login_page_background.jpg';
import saorsa_logo2_img from '../assets/img/saorsa_logo2.jpg';
import FormItem from '../components/FormItem';
import { signIn } from '../store/action-creators/auth';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import { Button, Flex, InputText, Spinner, Text } from '../ui';

const LoginPage: FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const loading = useTypedSelector((state) => state.auth.loading);
  const dispatch = useDispatch();

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleSignIn = () => {
    dispatch(signIn(email, password));
  };
  return (
    <Root>
      <FormWrapper>
        <Flex alignItems="center">
          <SaorsaLogoIcon1 />
        </Flex>
        <SaorsaLogoWrapper>
          <img alt="logo" src={saorsa_logo2_img} className="saorsa-logo-img" />
        </SaorsaLogoWrapper>
        <Text
          variant="body2"
          color="color3"
          textAlign="center"
          mt="15px"
          mb="15px"
        >
          Log in to continue:
        </Text>
        <Form name="basic" layout={'vertical'} onFinish={handleSignIn}>
          <FormItem
            name={'email'}
            label={'Enter your email'}
            rules={[
              {
                required: true,
                message: 'Incorrect email!',
                type: 'email',
              },
            ]}
          >
            <InputText value={email} type={'email'} onChange={handleEmail} />
          </FormItem>
          <FormItem
            name={'password'}
            label={'Enter your password'}
            rules={[
              {
                required: true,
                message: 'Incorrect password!',
                type: 'string',
                min: 5,
              },
            ]}
          >
            <InputText
              value={password}
              type={'password'}
              onChange={handlePassword}
            />
          </FormItem>
          <Button
            width="100%"
            variant="primary"
            text={'Log in'}
            disabled={loading}
          >
            <Spinner isRequesting={loading} variant={'small'} />
          </Button>
          <Text variant="body2" color="color3" textAlign="center" mt="15px">
            Forgot your password?
          </Text>
        </Form>
      </FormWrapper>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  padding: 0;
  margin: 0;
  background-image: url(${login_page_background_img});
  background-size: 100% 100%;
`;

const FormWrapper = styled.div`
  width: 370px;
  height: auto;
  background: ${(props) => props.theme.colors.color1};
  border-radius: ${(props) => props.theme.borderRadius.big};
  padding: 30px 40px;
`;

const SaorsaLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 7px;

  img {
    width: 98px;
    height: 19px;
  }
`;

export default LoginPage;
