import {
  DocumentAction,
  DocumentActionTypes,
  DocumentState,
} from '../types/document';

const initialState: DocumentState = {
  requests: [],
  totalPages: 0,
  loading: false,
  error: null,
};

export const documentReducer = (
  state = initialState,
  action: DocumentAction,
): DocumentState => {
  switch (action.type) {
    case DocumentActionTypes.FETCH_DOCUMENTS:
      return { ...state, loading: true };
    case DocumentActionTypes.FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        requests: action.payload.requests,
        totalPages: action.payload.totalPages,
      };
    case DocumentActionTypes.FETCH_DOCUMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        requests: [],
        totalPages: 0,
      };
    case DocumentActionTypes.DENY_DOCUMENT_REQUEST:
      return { ...state, loading: true, error: null };
    case DocumentActionTypes.DENY_DOCUMENT_REQUEST_SUCCESS:
      return { ...state, loading: false, error: null };
    case DocumentActionTypes.DENY_DOCUMENT_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case DocumentActionTypes.APPROVE_DOCUMENT_REQUEST:
      return { ...state, loading: true, error: null };
    case DocumentActionTypes.APPROVE_DOCUMENT_REQUEST_SUCCESS:
      return { ...state, loading: false, error: null };
    case DocumentActionTypes.APPROVE_DOCUMENT_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case DocumentActionTypes.DOWNLOAD_DOCUMENT:
      return { ...state, loading: true, error: null };
    case DocumentActionTypes.DOWNLOAD_DOCUMENT_SUCCESS:
      return { ...state, loading: false, error: null };
    case DocumentActionTypes.DOWNLOAD_DOCUMENT_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
