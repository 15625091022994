import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePagination, useTable } from 'react-table';
import styled from 'styled-components';

import {
  RefreshDataIcon,
  RightArrowIcon,
  UploadIcon,
} from '../../assets/icons';
import Header from '../../components/Header';
import { Pagination } from '../../components/Pagination';
import {
  approveDocumentRequest,
  denyDocumentRequest,
  downloadDocument,
  fetchDocumentRequests,
} from '../../store/action-creators/document';
import useInterval from '../../store/hooks/useInterval';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import { IDocument, IDocumentRequest } from '../../store/types/document';
import { Button, Flex, Spinner, Text } from '../../ui';
import {
  ApartmentModal,
  ApproveModal,
  AreYouSureModal,
  BookingModal,
  DenyModal,
  UserModal,
} from './Modals';

const DocumentsList: React.FC = () => {
  const dispatch = useDispatch();
  const { requests, totalPages, loading } = useTypedSelector(
    (state) => state.documents,
  );
  const [modalVisible, setModalVisible] = useState({
    areYouSureApprove: false,
    areYouSureDeny: false,
    approve: false,
    deny: false,
    user: false,
    apartment: false,
    booking: false,
  });
  const [requestId, setRequestId] = useState({
    approve: 0,
    deny: 0,
  });
  const [modalApproveInput, setModalApproveInput] = useState({
    salesTax: 0,
    additionalCharges: 0,
  });
  const [denyReason, setDenyReason] = useState('');
  const [modalUserInput, setModalUserInput] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [modalApartmentInput, setModalApartmentInput] = useState({
    address: '',
    name: '',
    price: 0,
    bathrooms: 0,
    bedrooms: 0,
    capacity: 0,
    floorSpace: 0,
    description: '',
  });
  const [modalBookingInput, setModalBookingInput] = useState({
    downpayment: 0,
    term: 0,
    age: 0,
    annualIncomes: 0,
    otherIncomes: 0,
    leasePayment: 0,
  });

  const onApproveClick = useCallback(
    (id: number) => {
      setModalVisible({ ...modalVisible, areYouSureApprove: true });
      setRequestId({ ...requestId, approve: id });
    },
    [modalVisible, requestId],
  );

  const onDenyClick = useCallback(
    (id: number) => {
      setModalVisible({ ...modalVisible, areYouSureDeny: true });
      setRequestId({ ...requestId, deny: id });
    },
    [modalVisible, requestId],
  );

  const onAreYouSureClose = () => {
    if (modalVisible.areYouSureApprove)
      setModalVisible({ ...modalVisible, areYouSureApprove: false });
    else setModalVisible({ ...modalVisible, areYouSureDeny: false });
  };

  const onAreYouSureSuccess = () => {
    if (modalVisible.areYouSureApprove) {
      setModalVisible({
        ...modalVisible,
        areYouSureApprove: false,
        approve: true,
      });
    } else
      setModalVisible({ ...modalVisible, areYouSureDeny: false, deny: true });
  };

  const data: IDocumentRequest[] = React.useMemo(() => requests, [requests]);
  const columns: any = React.useMemo(
    () => [
      {
        id: 'name',
        Cell: ({ row }: any) => (
          <Text variant="body2">Booking # {row.original.id}</Text>
        ),
      },
      {
        id: 'documents',
        Cell: ({ row }: any) => (
          <Flex>
            {row.original.documents.map(
              (document: IDocument, index: number) => (
                <Flex
                  key={document.id}
                  flexDirection="row"
                  justifyContent="space-between"
                  m="6px 0px"
                >
                  <div style={{ wordBreak: 'break-all' }}>
                    <Text variant="body2" color="color3">
                      {index + 1 + '. ' + document.name}
                    </Text>
                  </div>
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    ml="20px"
                    cursor="pointer"
                  >
                    <Flex
                      onClick={() => dispatch(downloadDocument(document.id))}
                    >
                      <Text variant="body2" color="color0">
                        Download
                      </Text>
                    </Flex>
                    <Flex ml="5px">
                      <UploadIcon />
                    </Flex>
                  </Flex>
                </Flex>
              ),
            )}
          </Flex>
        ),
        width: 300,
        minWidth: 150,
        maxWidth: 300,
      },
      {
        id: 'info',
        Cell: ({ row }: any) => (
          <Flex>
            <Flex
              flexDirection="row"
              alignItems="center"
              cursor="pointer"
              m="6px 0px"
              onClick={() => {
                setModalVisible({ ...modalVisible, user: true });
                setModalUserInput({
                  firstName: row.original.booking.user.firstName,
                  lastName: row.original.booking.user.lastName,
                  email: row.original.booking.user.email,
                  phone: row.original.booking.user.phone,
                });
              }}
            >
              <Text variant="body2bold">User</Text>
              <Flex ml="11px">
                <RightArrowIcon />
              </Flex>
            </Flex>
            <Flex
              flexDirection="row"
              alignItems="center"
              cursor="pointer"
              m="6px 0px"
              onClick={() => {
                setModalVisible({ ...modalVisible, apartment: true });
                setModalApartmentInput({
                  address: row.original.booking.property.address,
                  name: row.original.booking.property.name,
                  price: row.original.booking.property.price,
                  bathrooms: row.original.booking.property.bathrooms,
                  bedrooms: row.original.booking.property.bedrooms,
                  capacity: row.original.booking.property.capacity,
                  floorSpace: row.original.booking.property.floorSpace,
                  description: row.original.booking.property.description,
                });
              }}
            >
              <Text variant="body2bold">Apartments</Text>
              <Flex ml="11px">
                <RightArrowIcon />
              </Flex>
            </Flex>
            <Flex
              flexDirection="row"
              alignItems="center"
              cursor="pointer"
              m="6px 0px"
              onClick={() => {
                setModalVisible({ ...modalVisible, booking: true });
                setModalBookingInput({
                  downpayment: row.original.booking.dealTerms.downpayment,
                  term: row.original.booking.dealTerms.term,
                  age: row.original.booking.dealTerms.age,
                  annualIncomes: row.original.booking.dealTerms.annualIncomes,
                  otherIncomes: row.original.booking.dealTerms.otherIncomes,
                  leasePayment: row.original.booking.dealTerms.leasePayment,
                });
              }}
            >
              <Text variant="body2bold">Booking</Text>
              <Flex ml="11px">
                <RightArrowIcon />
              </Flex>
            </Flex>
          </Flex>
        ),
      },
      {
        id: 'buttons',
        Cell: ({ row }: any) => (
          <Flex flexDirection="row">
            <Flex width="141px" mr="5px">
              <Button
                width="100%"
                variant="primary"
                text={'APPROVE'}
                onClick={() => onApproveClick(row.original.id)}
              />
            </Flex>
            <Flex width="141px" ml="5px">
              <Button
                width="100%"
                variant="secondary"
                text={'DENY'}
                onClick={() => onDenyClick(row.original.id)}
              />
            </Flex>
          </Flex>
        ),
      },
    ],
    [onApproveClick, onDenyClick, modalVisible, dispatch],
  );

  const {
    getTableProps,
    getTableBodyProps,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 3 },
      manualPagination: true,
      pageCount: totalPages,
    },
    usePagination,
  );

  useInterval(() => refreshRequests(), 1000 * 60);

  useEffect(() => {
    dispatch(fetchDocumentRequests(pageIndex));
  }, [dispatch, pageIndex]);

  const refreshRequests = () => {
    dispatch(fetchDocumentRequests(pageIndex));
  };

  const onApprove = async () => {
    await dispatch(
      approveDocumentRequest(
        modalApproveInput.salesTax,
        modalApproveInput.additionalCharges,
        requestId.approve,
      ),
    );
    await refreshRequests();
    setModalVisible({ ...modalVisible, approve: false });
    setRequestId({ ...requestId, approve: 0 });
    setModalApproveInput({
      salesTax: 0,
      additionalCharges: 0,
    });
  };

  const onDeny = async () => {
    await dispatch(denyDocumentRequest(denyReason, requestId.deny));
    await refreshRequests();
    setModalVisible({ ...modalVisible, deny: false });
    setRequestId({ ...requestId, deny: 0 });
    setDenyReason('');
  };

  return (
    <div>
      <AreYouSureModal
        isVisible={
          modalVisible.areYouSureApprove || modalVisible.areYouSureDeny
        }
        onCancel={() => onAreYouSureClose()}
        onSuccess={() => onAreYouSureSuccess()}
      />
      <ApproveModal
        isVisible={modalVisible.approve}
        onCancel={() => setModalVisible({ ...modalVisible, approve: false })}
        taxValue={modalApproveInput.salesTax}
        onTaxValueChange={(value: string | number) => {
          if (typeof value === 'number')
            setModalApproveInput({ ...modalApproveInput, salesTax: value });
        }}
        additionalCharges={modalApproveInput.additionalCharges}
        onAdditionalChargesValueChange={(value: string | number) => {
          if (typeof value === 'number')
            setModalApproveInput({
              ...modalApproveInput,
              additionalCharges: value,
            });
        }}
        onApprove={() => onApprove()}
        loading={loading}
      />
      <DenyModal
        isVisible={modalVisible.deny}
        onCancel={() => setModalVisible({ ...modalVisible, deny: false })}
        denyReasonValue={denyReason}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setDenyReason(e.target.value)
        }
        onDeny={() => onDeny()}
        loading={loading}
      />
      <UserModal
        isVisible={modalVisible.user}
        onCancel={() => setModalVisible({ ...modalVisible, user: false })}
        user={modalUserInput}
      />
      <ApartmentModal
        isVisible={modalVisible.apartment}
        onCancel={() => setModalVisible({ ...modalVisible, apartment: false })}
        apartment={modalApartmentInput}
      />
      <BookingModal
        isVisible={modalVisible.booking}
        onCancel={() => setModalVisible({ ...modalVisible, booking: false })}
        booking={modalBookingInput}
      />
      <Header goBackIcon={false} title={'List of documents'} />
      <ContentWrapper>
        <Flex flexDirection="row" alignItems="center">
          <Text variant="title1bold">Documents</Text>
          <Flex ml="20px" cursor="pointer" onClick={() => refreshRequests()}>
            <RefreshDataIcon />
          </Flex>
        </Flex>
        <TableContainer>
          <SpinnerWrapper>
            <Spinner isRequesting={loading} variant={'big'} />
          </SpinnerWrapper>
          <Table {...getTableProps()}>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <TableDataRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableDataCell
                          {...cell.getCellProps({
                            style: {
                              width: cell.column.width,
                              minWidth: cell.column.minWidth,
                              maxWidth: cell.column.maxWidth,
                            },
                          })}
                        >
                          {cell.render('Cell')}
                        </TableDataCell>
                      );
                    })}
                  </TableDataRow>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>
        <Pagination
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex + 1}
        />
      </ContentWrapper>
    </div>
  );
};

const ContentWrapper = styled.div`
  margin-top: 30px;
  padding: 30px;
  background: ${(props) => props.theme.colors.color1};
  border-radius: ${(props) => props.theme.borderRadius.big};
`;

const TableContainer = styled.div`
  position: relative;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  left: calc(50% - 18px);
  top: calc(50% - 18px);
`;

const Table = styled.table`
  width: 100%;
`;

const TableDataRow = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.colors.color4};
`;

const TableDataCell = styled.td`
  padding: 15px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.normal};
`;

export default DocumentsList;
