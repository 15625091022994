import { Divider as AntDivider } from 'antd';
import styled from 'styled-components';

const Divider = () => {
  return <StyledDivider />;
};

const StyledDivider = styled(AntDivider)`
  background: ${(props) => props.theme.colors.color4};
`;

export default Divider;
