import { UserAction, UserActionTypes, UserState } from '../types/user';

const initialState: UserState = {
  users: [],
  totalPages: 0,
  loading: false,
  error: null,
};

export const userReducer = (
  state = initialState,
  action: UserAction,
): UserState => {
  switch (action.type) {
    case UserActionTypes.FETCH_USERS:
      return { ...state, loading: true };
    case UserActionTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        totalPages: action.payload.totalPages,
      };
    case UserActionTypes.FETCH_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        users: [],
        totalPages: 0,
      };
    case UserActionTypes.CREATE_USER:
      return { ...state, loading: true, error: null };
    case UserActionTypes.CREATE_USER_SUCCESS:
      return { ...state, loading: false, error: null };
    case UserActionTypes.CREATE_USER_ERROR:
      return { ...state, loading: false, error: action.payload };
    case UserActionTypes.EDIT_USER:
      return { ...state, loading: true, error: null };
    case UserActionTypes.EDIT_USER_SUCCESS:
      return { ...state, loading: false, error: null };
    case UserActionTypes.EDIT_USER_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
