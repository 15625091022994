import styled from 'styled-components';
import {
  ColorProps,
  FlexboxProps,
  SpaceProps,
  TypographyProps,
  color,
  compose,
  flexbox,
  space,
  typography,
} from 'styled-system';

import { ThemeType } from '../themes';

const Text: React.FC<TextProps> = ({ variant, children, ...props }) => {
  return (
    <Root $variant={variant} {...props}>
      {children}
    </Root>
  );
};

export interface TextProps
  extends ColorProps,
    TypographyProps,
    SpaceProps,
    FlexboxProps {
  variant: Variant;
}

// interface RootProps {
//   $variant: Variant;
// }

export type Variant = keyof ThemeType['typography'];

const Root = styled.p<any>`
  ${({ $variant, theme: { typography: themeTypography } }) =>
    themeTypography[$variant]}
  ${compose(color, space, typography, flexbox)};
`; //TODO: fix any

export default Text;
