import { SiderItemIconComponentProps } from '../../types/sider-items';

const SupportIcon = ({ color }: SiderItemIconComponentProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.03643 0C8.03214 0.00428687 8.02357 0.00428687 8.01071 0.00428687C3.67239 0.00428687 0.144287 3.53238 0.144287 7.87069C0.144287 9.64117 0.748737 11.3602 1.85475 12.7449L0.710155 15.4027C0.615844 15.6214 0.718729 15.8743 0.933073 15.9643C1.01024 15.9986 1.09597 16.0072 1.17742 15.9943L5.37428 15.257C6.21451 15.5613 7.0976 15.7157 7.98927 15.7114C12.3276 15.7114 15.8557 12.1833 15.8557 7.84497C15.8643 3.51952 12.3619 0.00428687 8.03643 0ZM7.99356 14.8583C7.16619 14.8583 6.3474 14.7083 5.57148 14.4167C5.4986 14.3867 5.42143 14.3824 5.34427 14.3953L1.81188 15.0126L2.74642 12.8392C2.81073 12.6891 2.785 12.5134 2.67783 12.3891C2.17198 11.8018 1.76901 11.133 1.48608 10.4085C1.16885 9.5983 1.00595 8.73664 1.00595 7.86641C1.00595 3.99965 4.15252 0.857374 8.015 0.857374C11.8689 0.8488 14.9983 3.96535 15.0026 7.81925C15.0026 7.82782 15.0026 7.8364 15.0026 7.84497C15.0026 11.716 11.856 14.8583 7.99356 14.8583Z"
      fill={color}
    />
  </svg>
);

export default SupportIcon;
