import { IRole, TUserRole } from '../store/types/user';
import { IAdBody } from '../types/ad';

export function isRoleExists(roles: IRole[], role: TUserRole) {
  let roleExists = false;
  roles.map((el: IRole) => {
    if (el.name === role) return (roleExists = true);
    else return null;
  });
  return roleExists;
}

export function handleResponseErrorAlert(status: number) {
  switch (status) {
    case 401:
      return alert('Auth Error');
    case 404:
      return alert('Not found');
    case 409:
      return alert('Email is already exists');
    case 500:
      return alert('Server Error');
    default:
      return null;
  }
}

export function simpleAdValidation(adObj: IAdBody) {
  if (adObj.country === '') {
    alert('Empty country field');
    return false;
  } else if (adObj.city === '') {
    alert('Empty city field');
    return false;
  } else if (adObj.address === '') {
    alert('Empty address field');
    return false;
  } else if (adObj.preview && adObj.preview.uploadObject.name === undefined) {
    alert('Empty preview');
    return false;
  } else if (adObj.images && adObj.images.length === 0) {
    alert('Empty gallery');
    return false;
  } else return true;
}

export function getAddressFromLocationObject(locationObject: any) {
  let country: string = '';
  let city: string = '';
  let streetName: string = '';
  let streetNumber: string = '';
  for (let i = 0; i < locationObject.address_components.length; i++) {
    for (
      let j = 0;
      j < locationObject.address_components[i].types.length;
      j++
    ) {
      switch (locationObject.address_components[i].types[j]) {
        case 'country':
          country = locationObject.address_components[i].long_name;
          break;
        case 'locality':
          city = locationObject.address_components[i].long_name;
          break;
        case 'sublocality':
          city = locationObject.address_components[i].long_name;
          break;
        case 'postal_town':
          city = locationObject.address_components[i].long_name;
          break;
        case 'route':
          streetName = locationObject.address_components[i].long_name;
          break;
        case 'street_number':
          streetNumber = locationObject.address_components[i].long_name;
          break;
      }
    }
  }
  return {
    country,
    city,
    streetAddress: streetName && streetNumber ? `${streetNumber}' '${streetName}` : '',
  };
}
