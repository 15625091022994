import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { usePagination, useTable } from 'react-table';
import styled, { useTheme } from 'styled-components';

import {
  BathroomsIcon,
  BedroomsIcon,
  FloorSpaceIcon,
} from '../../assets/icons';
import Header from '../../components/Header';
import { Pagination } from '../../components/Pagination';
import { fetchAds } from '../../store/action-creators/ad';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import { IAds } from '../../store/types/ad';
import { Button, Checkbox, Flex, Spinner, Switch, Text } from '../../ui';

const AdsListPage = () => {
  const { colors } = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { ads, totalPages, loading } = useTypedSelector((state) => state.ad);
  const data: IAds[] = React.useMemo(() => ads, [ads]);
  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Image',
        Cell: ({ row }: any) => (
          <Flex>
            <Flex width="175px" height="125px">
              <img
                alt="ad"
                src={row.original.preview.url}
                width="100%"
                height="100%"
                style={{ objectFit: 'contain' }}
              />
            </Flex>
          </Flex>
        ),
        width: 190,
        minWidth: 190,
        maxWidth: 190,
      },
      {
        Header: 'Description',
        Cell: ({ row }: any) => (
          <Flex
            height="100%"
            cursor="pointer"
            onClick={() => history.push(`/edit-ad/${row.original.id}`)}
          >
            <Text variant="body2bold" color="color7">
              {row.original.name}
            </Text>
            <Text variant="footnote1" color="color3">
              {row.original.country ? row.original.country.name : ''}
              {', '}
              {row.original.city ? row.original.city.name : ''}
            </Text>
            <Flex mb="10px" mt="10px" flexDirection="row">
              <Flex flexDirection="row" mr="20px">
                <Flex mr="6px" justifyContent="center">
                  <FloorSpaceIcon />
                </Flex>
                <Text variant="body2bold">
                  {row.original.floorSpace} m<sup>2</sup>
                </Text>
              </Flex>
              <Flex flexDirection="row" mr="20px">
                <Flex mr="6px" justifyContent="center">
                  <BedroomsIcon />
                </Flex>
                <Text variant="body2bold">{row.original.bedrooms}</Text>
              </Flex>
              <Flex flexDirection="row">
                <Flex mr="6px" justifyContent="center">
                  <BathroomsIcon />
                </Flex>
                <Text variant="body2bold">{row.original.bathrooms}</Text>
              </Flex>
            </Flex>
            <div
              style={{
                wordBreak: 'break-all',
                height: '150px',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
              }}
            >
              <Text variant="body3" color="color3">
                {row.original.description}
              </Text>
            </div>
          </Flex>
        ),
        width: 450,
        minWidth: 150,
        maxWidth: 450,
      },
      {
        id: 'price',
        Header: () => (
          <Text variant="body2bold" textAlign="center">
            Price
          </Text>
        ),
        Cell: ({ row }: any) => (
          <Text variant="body3" textAlign="center">
            {row.original.price}
          </Text>
        ),
      },
      {
        id: 'booking',
        Header: () => (
          <Text variant="body2bold" textAlign="center">
            Booking
          </Text>
        ),
        Cell: ({ row }: any) => (
          <Flex alignItems="center">
            <Checkbox color={colors.color8} checked={false} />
          </Flex>
        ),
      },
      {
        id: 'contribution',
        Header: () => (
          <Text variant="body2bold" textAlign="center">
            Contribution
          </Text>
        ),
        Cell: ({ row }: any) => (
          <Text variant="body3" textAlign="center">
            10%
          </Text>
        ),
      },
      {
        id: 'adversting',
        Header: () => (
          <Text variant="body2bold" textAlign="center">
            Adversting
          </Text>
        ),
        Cell: ({ row }: any) => (
          <Flex alignItems="center">
            <Switch />
          </Flex>
        ),
      },
    ],
    [history, colors.color8],
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 3 },
      manualPagination: true,
      pageCount: totalPages,
    },
    usePagination,
  );

  useEffect(() => {
    dispatch(fetchAds(pageIndex));
  }, [dispatch, pageIndex]);
  return (
    <div>
      <Header goBackIcon={false} title={'List of ads'} />
      <Flex flexDirection="row" justifyContent="flex-end" mt="30px">
        <Button
          width="152px"
          variant="primary"
          text={'+ ADD'}
          onClick={() => history.push('/add-ad')}
        />
      </Flex>
      <ContentWrapper>
        <TableContainer>
          <SpinnerWrapper>
            <Spinner isRequesting={loading} variant={'big'} />
          </SpinnerWrapper>
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableHeaderCell {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </TableHeaderCell>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <TableDataRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableDataCell
                          {...cell.getCellProps({
                            style: {
                              width: cell.column.width,
                              minWidth: cell.column.minWidth,
                              maxWidth: cell.column.maxWidth,
                            },
                          })}
                        >
                          {cell.render('Cell')}
                        </TableDataCell>
                      );
                    })}
                  </TableDataRow>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>
        <Pagination
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex + 1}
        />
      </ContentWrapper>
    </div>
  );
};

const ContentWrapper = styled.div`
  margin-top: 30px;
  padding: 30px;
  background: ${(props) => props.theme.colors.color1};
  border-radius: ${(props) => props.theme.borderRadius.big};
`;

const TableContainer = styled.div`
  position: relative;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  left: calc(50% - 18px);
  top: calc(50% - 18px);
  z-index: 10000;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHeaderCell = styled.th`
  text-align: left;
  padding: 30px 0px 0px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

const TableDataRow = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.colors.color4};
`;

const TableDataCell = styled.td`
  padding: 30px 0px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.normal};
`;

export default AdsListPage;
