import { Dispatch } from 'redux';

import { REACT_APP_API_URL } from '../../constants/api';
import { handleResponseErrorAlert } from '../../helpers/functions';
import { client } from '../../libs/Storage/StorageClient';
import { AuthAction, AuthActionTypes } from '../types/auth';
import { UserAction, UserActionTypes } from '../types/user';

export const fetchUsers = (page: number) => {
  return async (dispatch: Dispatch<UserAction | AuthAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCH_USERS });
      const authToken = await client.getAuthToken();
      const response = await fetch(
        `${REACT_APP_API_URL}/users?count=6&page=${page}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${authToken}`,
          },
        },
      );
      if (response.status === 200) {
        const users = await response.json();
        const payload = {
          users: users.data,
          totalPages: users.totalPages,
        };
        dispatch({ type: UserActionTypes.FETCH_USERS_SUCCESS, payload });
      } else {
        if (response.status === 401) {
          dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: response.statusText + ' ' + response.status,
          });
        }
        dispatch({
          type: UserActionTypes.FETCH_USERS_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
    } catch (e) {
      dispatch({
        type: UserActionTypes.FETCH_USERS_ERROR,
        payload: 'fetch users error',
      });
    }
  };
};

interface ICreateUserBody {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  roles: number[];
}

export const createUser = (body: ICreateUserBody) => {
  return async (dispatch: Dispatch<UserAction | AuthAction>) => {
    try {
      dispatch({ type: UserActionTypes.CREATE_USER });
      const authToken = await client.getAuthToken();
      const response = await fetch(`${REACT_APP_API_URL}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(body),
      });
      if (response.status === 201) {
        dispatch({ type: UserActionTypes.CREATE_USER_SUCCESS });
      } else {
        if (response.status === 401) {
          dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: response.statusText + ' ' + response.status,
          });
        }
        dispatch({
          type: UserActionTypes.CREATE_USER_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
    } catch (e) {
      dispatch({
        type: UserActionTypes.CREATE_USER_ERROR,
        payload: 'create user error',
      });
    }
  };
};

interface IEditUserBody {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roles: number[];
}

export const editUser = (body: IEditUserBody, id: number) => {
  return async (dispatch: Dispatch<UserAction | AuthAction>) => {
    try {
      dispatch({ type: UserActionTypes.EDIT_USER });
      const authToken = await client.getAuthToken();
      const response = await fetch(`${REACT_APP_API_URL}/users/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        dispatch({ type: UserActionTypes.EDIT_USER_SUCCESS });
      } else {
        if (response.status === 401) {
          dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: response.statusText + ' ' + response.status,
          });
        }
        dispatch({
          type: UserActionTypes.EDIT_USER_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
    } catch (e) {
      dispatch({
        type: UserActionTypes.EDIT_USER_ERROR,
        payload: 'edit user error',
      });
    }
  };
};
