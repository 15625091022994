import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import styled from 'styled-components';

interface CustomSpinnerProps {
  isRequesting: boolean;
  variant: 'big' | 'small';
}

const Spinner: React.FC<CustomSpinnerProps> = ({ isRequesting, variant }) => {
  return (
    <Spin
      spinning={isRequesting}
      indicator={<StyledLoadingOutlined className={variant} />}
    />
  );
};

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${(props) => props.theme.colors.color2};

  &.big {
    font-size: ${(props) => props.theme.fontSize.pageTitle};
  }

  &.small {
    font-size: ${(props) => props.theme.fontSize.medium};
  }
`;

export default Spinner;
