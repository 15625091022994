import { Form, Modal } from 'antd';
import styled from 'styled-components';

import FormItem from '../../../components/FormItem';
import Flex from '../../../ui/Flex';
import InputText from '../../../ui/InputText';
import Text from '../../../ui/Text';

const UserModal: React.FC<UserModalProps> = ({ isVisible, onCancel, user }) => {
  return (
    <StyledModal visible={isVisible} onCancel={onCancel} footer={null}>
      <Text variant="title1bold">User</Text>
      <Flex mt="30px">
        <Form name="basic" layout={'vertical'}>
          <FormItem name={'firstName'} label={'First name'}>
            <InputText placeholder={user.firstName} disabled={true} />
          </FormItem>
          <FormItem name={'lastName'} label={'Last name'}>
            <InputText placeholder={user.lastName} disabled={true} />
          </FormItem>
          <FormItem name={'email'} label={'E-mail'}>
            <InputText placeholder={user.email} disabled={true} />
          </FormItem>
          <FormItem name={'phone'} label={'Phone'}>
            <InputText placeholder={user.phone} disabled={true} />
          </FormItem>
        </Form>
      </Flex>
    </StyledModal>
  );
};

interface User {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

interface UserModalProps {
  isVisible: boolean;
  onCancel: () => void;
  user: User;
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 440px;
  }
  .ant-modal-body {
    padding: 30px;
  }
`;

export default UserModal;
