import { combineReducers } from 'redux';

import { adReducer } from './adReducer';
import { authReducer } from './authReducer';
import { documentReducer } from './documentReducer';
import { userReducer } from './userReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  ad: adReducer,
  documents: documentReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
