const BedroomsIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.707 6.12133H13.398C13.2363 6.12133 13.1051 6.27559 13.1051 6.46589V6.68286H6.15894C5.99716 6.68286 5.86597 6.83711 5.86597 7.02741V7.50121H5.56075V6.16509C5.56075 5.61604 5.18098 5.16939 4.71416 5.16939H1.89492V1.59456C1.89492 1.40426 1.76373 1.25 1.60195 1.25H0.292969C0.131191 1.25 0 1.40426 0 1.59456V14.6554C0 14.8457 0.131191 15 0.292969 15H1.60195C1.76373 15 1.89492 14.8457 1.89492 14.6554V12.4145H13.1051V14.6554C13.1051 14.8457 13.2363 15 13.398 15H14.707C14.8688 15 15 14.8457 15 14.6554V6.46589C15 6.27559 14.8688 6.12133 14.707 6.12133ZM6.4519 7.37197H13.1051V10.5395H6.4519V7.37197ZM4.71413 5.8585C4.85786 5.8585 4.97479 5.99605 4.97479 6.16509V7.50121H1.89492V5.8585H4.71413ZM1.30898 14.3109H0.585938V1.93911H1.30898V14.3109ZM1.89492 11.7254V8.19033H5.86597V10.8841C5.86597 11.0744 5.99716 11.2286 6.15894 11.2286H13.1051V11.7254H1.89492ZM14.4141 14.3109H13.691V6.81045H14.4141V14.3109Z"
      fill="#979797"
    />
  </svg>
);

export default BedroomsIcon;
