import { PreviewImage } from '../../../../types/ad';
import { Flex, Text } from '../../../../ui';
import ImageItem from './ImageItem';
import UploadItem from './UploadItem';

interface PhotoWallProps {
  mainImageSrc?: string;
  handleMainImage?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  galleryImages: PreviewImage[];
  handleGalleryImages?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  deleteGalleryImage?: (src: string) => void;
}

const PhotoWall: React.FC<PhotoWallProps> = ({
  mainImageSrc,
  handleMainImage,
  galleryImages,
  handleGalleryImages,
  deleteGalleryImage,
}) => {
  return (
    <Flex>
      <Text variant="title1bold" mb="10px">
        Photo
      </Text>
      <Text variant="footnote1" color="color3" mb="4px">
        Main image
      </Text>
      <Flex mb="15px">
        {mainImageSrc ? (
          <ImageItem src={mainImageSrc} onChange={handleMainImage} />
        ) : (
          <UploadItem onChange={handleMainImage} />
        )}
      </Flex>
      <Text variant="footnote1" color="color3" mb="4px">
        Gallery
      </Text>
      <Flex flexDirection="row" flexWrap="wrap" gridGap="10px">
        {galleryImages.map((item: PreviewImage) => (
          <ImageItem
            key={item.uploadObject.name}
            src={item.src}
            deleteGalleryImage={deleteGalleryImage}
          />
        ))}
        <UploadItem onChange={handleGalleryImages} />
      </Flex>
    </Flex>
  );
};

export default PhotoWall;
