const DeleteUserIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M11.9618 2.88623L11.0054 13.7839H3.99434L3.0382 2.88623L1.82666 2.99243L2.80025 14.0878C2.85149 14.5992 3.29522 15.0001 3.8107 15.0001H11.1891C11.7044 15.0001 12.1483 14.5994 12.2004 14.0805L13.1733 2.99243L11.9618 2.88623Z"
        fill="#B11733"
      />
      <path
        d="M9.72982 0H5.27036C4.71149 0 4.25684 0.454658 4.25684 1.01353V2.93921H5.47304V1.2162H9.52708V2.93918H10.7433V1.0135C10.7433 0.454658 10.2887 0 9.72982 0Z"
        fill="#B11733"
      />
      <path
        d="M13.9868 2.33081H1.01388C0.677988 2.33081 0.405762 2.60304 0.405762 2.93893C0.405762 3.27481 0.677988 3.54704 1.01388 3.54704H13.9869C14.3227 3.54704 14.595 3.27481 14.595 2.93893C14.595 2.60304 14.3227 2.33081 13.9868 2.33081Z"
        fill="#B11733"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DeleteUserIcon;
