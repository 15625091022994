const EditUploadIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M9.68871 1.21689L8.78254 0.310646C8.3685 -0.103396 7.69487 -0.103376 7.28082 0.310646C6.891 0.700509 0.936793 6.65517 0.538884 7.0531C0.496501 7.09548 0.468103 7.15181 0.457986 7.2069L0.00490067 9.65378C-0.0126579 9.74864 0.0175764 9.84608 0.085799 9.9143C0.1541 9.9826 0.25156 10.0128 0.346306 9.99522L2.79296 9.54208C2.8495 9.53153 2.90509 9.50286 2.94677 9.46116L9.68871 2.71871C10.1037 2.30371 10.1038 1.63195 9.68871 1.21689ZM0.65861 9.34143L0.932692 7.86128L2.13867 9.06733L0.65861 9.34143ZM2.73962 8.83965L1.16035 7.26028L6.92616 1.49402L8.50543 3.07342L2.73962 8.83965ZM9.27439 2.30439L8.91973 2.6591L7.34045 1.07971L7.69512 0.725001C7.88069 0.539416 8.18262 0.539396 8.36823 0.725001L9.27439 1.63125C9.46043 1.81728 9.46043 2.11834 9.27439 2.30439Z"
        fill="#121212"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EditUploadIcon;
