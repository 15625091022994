import { Dispatch } from 'redux';

import { REACT_APP_API_URL } from '../../constants/api';
import { handleResponseErrorAlert } from '../../helpers/functions';
import { client } from '../../libs/Storage/StorageClient';
import { AuthAction, AuthActionTypes } from '../types/auth';
import { DocumentAction, DocumentActionTypes } from '../types/document';

export const fetchDocumentRequests = (page: number) => {
  return async (dispatch: Dispatch<DocumentAction | AuthAction>) => {
    try {
      dispatch({ type: DocumentActionTypes.FETCH_DOCUMENTS });
      const authToken = await client.getAuthToken();
      const response = await fetch(
        `${REACT_APP_API_URL}/document-groups?status=2&count=3&page=${page}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${authToken}`,
          },
        },
      );
      if (response.status === 200) {
        const requests = await response.json();
        const payload = {
          requests: requests.data,
          totalPages: requests.totalPages,
        };
        dispatch({
          type: DocumentActionTypes.FETCH_DOCUMENTS_SUCCESS,
          payload,
        });
      } else {
        if (response.status === 401) {
          dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: response.statusText + ' ' + response.status,
          });
        }
        dispatch({
          type: DocumentActionTypes.FETCH_DOCUMENTS_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
    } catch (e) {
      dispatch({
        type: DocumentActionTypes.FETCH_DOCUMENTS_ERROR,
        payload: 'fetch documents error',
      });
    }
  };
};

export const denyDocumentRequest = (reason: string, requestId: number) => {
  return async (dispatch: Dispatch<DocumentAction | AuthAction>) => {
    try {
      dispatch({ type: DocumentActionTypes.DENY_DOCUMENT_REQUEST });
      const authToken = await client.getAuthToken();
      const response = await fetch(
        `${REACT_APP_API_URL}/document-group/${requestId}/deny`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            reason,
          }),
        },
      );
      if (response.status === 201) {
        dispatch({ type: DocumentActionTypes.DENY_DOCUMENT_REQUEST_SUCCESS });
      } else {
        if (response.status === 401) {
          dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: response.statusText + ' ' + response.status,
          });
        }
        dispatch({
          type: DocumentActionTypes.DENY_DOCUMENT_REQUEST_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
      return response.status;
    } catch (e) {
      dispatch({
        type: DocumentActionTypes.DENY_DOCUMENT_REQUEST_ERROR,
        payload: 'deny document error',
      });
    }
  };
};

export const approveDocumentRequest = (
  tax: number,
  serviceFee: number,
  requestId: number,
) => {
  return async (dispatch: Dispatch<DocumentAction | AuthAction>) => {
    try {
      dispatch({ type: DocumentActionTypes.APPROVE_DOCUMENT_REQUEST });
      const authToken = await client.getAuthToken();
      const response = await fetch(
        `${REACT_APP_API_URL}/document-group/${requestId}/approve`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            tax,
            serviceFee,
          }),
        },
      );
      if (response.status === 200) {
        dispatch({
          type: DocumentActionTypes.APPROVE_DOCUMENT_REQUEST_SUCCESS,
        });
      } else {
        if (response.status === 401) {
          dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: response.statusText + ' ' + response.status,
          });
        }
        dispatch({
          type: DocumentActionTypes.APPROVE_DOCUMENT_REQUEST_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
      return response.status;
    } catch (e) {
      dispatch({
        type: DocumentActionTypes.APPROVE_DOCUMENT_REQUEST_ERROR,
        payload: 'approve document error',
      });
    }
  };
};

export const downloadDocument = (id: number) => {
  return async (dispatch: Dispatch<DocumentAction | AuthAction>) => {
    try {
      dispatch({ type: DocumentActionTypes.DOWNLOAD_DOCUMENT });
      const authToken = await client.getAuthToken();
      const response = await fetch(`${REACT_APP_API_URL}/documents/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        dispatch({ type: DocumentActionTypes.DOWNLOAD_DOCUMENT_SUCCESS });
        const blob = await response.blob();
        let file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      } else {
        if (response.status === 401) {
          dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: response.statusText + ' ' + response.status,
          });
        }
        dispatch({
          type: DocumentActionTypes.DOWNLOAD_DOCUMENT_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
      return response.status;
    } catch (e) {
      dispatch({
        type: DocumentActionTypes.DOWNLOAD_DOCUMENT_ERROR,
        payload: 'download document error',
      });
    }
  };
};
