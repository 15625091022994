import { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { SiderItemIconComponentProps } from '../../types/sider-items';

interface ItemProps {
  name: string;
  IconComponent: React.FC<SiderItemIconComponentProps>;
}

const SiderItem = ({ name, IconComponent }: ItemProps) => {
  const [hovered, setHovered] = useState(false);
  const { colors } = useTheme();
  return (
    <Root
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <IconWrapper>
        <IconComponent color={hovered ? colors.color0 : colors.color1} />
      </IconWrapper>
      <Text color={hovered ? colors.color0 : colors.color1}>{name}</Text>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 30px;
  height: 46px;
  margin-bottom: 15px;

  &:hover {
    background: linear-gradient(
      270deg,
      #332b19 0%,
      rgba(51, 43, 25, 0.47) 99.04%
    );
    border-left: 4px solid ${(props) => props.theme.colors.color0};
    padding: 15px 26px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const Text = styled.p`
  padding: 0;
  margin: 0;
  font-size: ${(props) => props.theme.fontSize.medium};
  color: ${(props) => props.color};
`;

export default SiderItem;
