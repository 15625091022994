import { Dispatch } from 'redux';

import { REACT_APP_API_URL } from '../../constants/api';
import { handleResponseErrorAlert } from '../../helpers/functions';
import { client } from '../../libs/Storage/StorageClient';
import { IAdBody, PreviewImage } from '../../types/ad';
import { AdAction, AdActionTypes } from '../types/ad';
import { AuthAction, AuthActionTypes } from '../types/auth';

export const fetchAds = (page: number) => {
  return async (dispatch: Dispatch<AdAction | AuthAction>) => {
    try {
      dispatch({ type: AdActionTypes.FETCH_ADS });
      const authToken = await client.getAuthToken();
      const response = await fetch(
        `${REACT_APP_API_URL}/apartments?count=3&page=${page}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${authToken}`,
          },
        },
      );
      if (response.status === 200) {
        const ads = await response.json();
        const payload = {
          ads: ads.data,
          totalPages: ads.pagesCount,
        };
        dispatch({ type: AdActionTypes.FETCH_ADS_SUCCESS, payload });
      } else {
        if (response.status === 401) {
          dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: response.statusText + ' ' + response.status,
          });
        }
        dispatch({
          type: AdActionTypes.FETCH_ADS_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
    } catch (e) {
      dispatch({
        type: AdActionTypes.FETCH_ADS_ERROR,
        payload: 'fetch ads error',
      });
    }
  };
};

export const fetchPropertyTypes = () => {
  return async (dispatch: Dispatch<AdAction | AuthAction>) => {
    try {
      dispatch({ type: AdActionTypes.FETCH_PROPERTY_TYPES });
      const authToken = await client.getAuthToken();
      const response = await fetch(`${REACT_APP_API_URL}/property-types`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        const payload = await response.json();
        dispatch({ type: AdActionTypes.FETCH_PROPERTY_TYPES_SUCCESS, payload });
      } else {
        if (response.status === 401) {
          dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: response.statusText + ' ' + response.status,
          });
        }
        dispatch({
          type: AdActionTypes.FETCH_PROPERTY_TYPES_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
    } catch (e) {
      dispatch({
        type: AdActionTypes.FETCH_PROPERTY_TYPES_ERROR,
        payload: 'fetch property types error',
      });
    }
  };
};

export const fetchNovelty = () => {
  return async (dispatch: Dispatch<AdAction | AuthAction>) => {
    try {
      dispatch({ type: AdActionTypes.FETCH_NOVELTY });
      const authToken = await client.getAuthToken();
      const response = await fetch(`${REACT_APP_API_URL}/novelty`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        const payload = await response.json();
        dispatch({ type: AdActionTypes.FETCH_NOVELTY_SUCCESS, payload });
      } else {
        if (response.status === 401) {
          dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: response.statusText + ' ' + response.status,
          });
        }
        dispatch({
          type: AdActionTypes.FETCH_NOVELTY_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
    } catch (e) {
      dispatch({
        type: AdActionTypes.FETCH_NOVELTY_ERROR,
        payload: 'fetch novelty error',
      });
    }
  };
};

export const createAd = (body: IAdBody) => {
  return async (dispatch: Dispatch<AdAction | AuthAction>) => {
    try {
      const formData: FormData = new FormData();
      for (const name in body) {
        if (name === 'images') {
          body[name].forEach((element: PreviewImage) => {
            formData.append(name, element.uploadObject);
          });
        } else if (name === 'preview') {
          formData.append(name, body.preview.uploadObject);
        } else formData.append(name, (body as any)[name]);
      }
      dispatch({ type: AdActionTypes.CREATE_AD });
      const authToken = await client.getAuthToken();
      const response = await fetch(`${REACT_APP_API_URL}/apartments`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formData,
      });
      if (response.status === 201) {
        dispatch({ type: AdActionTypes.CREATE_AD_SUCCESS });
      } else {
        if (response.status === 401) {
          dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: response.statusText + ' ' + response.status,
          });
        }
        dispatch({
          type: AdActionTypes.CREATE_AD_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
    } catch (e) {
      dispatch({
        type: AdActionTypes.CREATE_AD_ERROR,
        payload: 'create ad error',
      });
    }
  };
};

export const editAd = (body: IAdBody, id: number) => {
  return async (dispatch: Dispatch<AdAction | AuthAction>) => {
    try {
      const formData: FormData = new FormData();
      for (const name in body) {
        if (name === 'images') {
          body[name].forEach((element: PreviewImage) => {
            formData.append(name, element.uploadObject);
          });
        } else if (name === 'preview') {
          formData.append(name, body.preview.uploadObject);
        } else formData.append(name, (body as any)[name]);
      }
      dispatch({ type: AdActionTypes.EDIT_AD });
      const authToken = await client.getAuthToken();
      const response = await fetch(`${REACT_APP_API_URL}/apartments/${id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formData,
      });
      if (response.status === 200) {
        dispatch({ type: AdActionTypes.EDIT_AD_SUCCESS });
      } else {
        if (response.status === 401) {
          dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: response.statusText + ' ' + response.status,
          });
        }
        dispatch({
          type: AdActionTypes.EDIT_AD_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
    } catch (e) {
      dispatch({
        type: AdActionTypes.EDIT_AD_ERROR,
        payload: 'edit ad error',
      });
    }
  };
};

export const deleteAd = (id: number) => {
  return async (dispatch: Dispatch<AdAction | AuthAction>) => {
    try {
      dispatch({ type: AdActionTypes.DELETE_AD });
      const authToken = await client.getAuthToken();
      const response = await fetch(`${REACT_APP_API_URL}/apartments/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        dispatch({ type: AdActionTypes.DELETE_AD_SUCCESS });
      } else {
        if (response.status === 401) {
          dispatch({
            type: AuthActionTypes.AUTH_ERROR,
            payload: response.statusText + ' ' + response.status,
          });
        }
        dispatch({
          type: AdActionTypes.DELETE_AD_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
    } catch (e) {
      dispatch({
        type: AdActionTypes.DELETE_AD_ERROR,
        payload: 'delete ad error',
      });
    }
  };
};
