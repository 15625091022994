import { Switch as AntSwitch } from 'antd';
import styled from 'styled-components';

const Switch = () => {
  return <StyledSwitch />;
};

const StyledSwitch = styled(AntSwitch)`
  width: 55px;
  height: 30px;
  background: ${(props) => props.theme.colors.color6};

  &.ant-switch-checked {
    background: ${(props) => props.theme.colors.color0};
  }

  .ant-switch-handle {
    width: 25px;
    height: 26px;

    &::before {
      border-radius: 100%;
    }
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 25px - 2px);
  }
`;

export default Switch;
