import { client } from '../../libs/Storage/StorageClient';
import { AuthAction, AuthActionTypes, AuthState } from '../types/auth';

const initialState: AuthState = {
  isAuthUser: !!localStorage.getItem('auth_token'),
  loading: false,
  error: null,
};

export const authReducer = (
  state = initialState,
  action: AuthAction,
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.AUTH_ONGOING:
      return { loading: true, error: null, isAuthUser: false };
    case AuthActionTypes.AUTH_SUCCESS:
      client.setAuthToken(action.payload.token);
      return { loading: false, error: null, isAuthUser: true };
    case AuthActionTypes.AUTH_ERROR:
      return { loading: false, error: action.payload, isAuthUser: false };
    case AuthActionTypes.AUTH_LOGOUT:
      client.removeToken();
      return { ...state, isAuthUser: false };
    default:
      return state;
  }
};
