import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useTypedSelector } from '../store/hooks/useTypedSelector';

interface AuthRouteProps {
  type: 'guest' | 'private';
  exact?: boolean;
  path: string;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ type, ...props }) => {
  const { isAuthUser } = useTypedSelector((state) => state.auth);
  if (type === 'guest' && isAuthUser) return <Redirect to="/home" />;
  else if (type === 'private' && !isAuthUser) return <Redirect to="/" />;

  return <Route {...props} />;
};

export default AuthRoute;
