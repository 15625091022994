import styled from 'styled-components';

import { CheckboxCheckedIcon, CheckboxEmptyIcon } from '../assets/icons';

interface CustomCheckboxProps {
  checked: boolean;
  color: string;
  onClick?: () => void;
}

const Checkbox: React.FC<CustomCheckboxProps> = ({
  checked,
  color,
  onClick,
}) => {
  return (
    <Root onClick={onClick}>
      <HiddenCheckbox />
      {checked ? <CheckboxCheckedIcon color={color} /> : <CheckboxEmptyIcon />}
    </Root>
  );
};

const Root = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export default Checkbox;
