const GoBackIcon = () => (
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.79995 3.00002L3.79995 9.00002L9.79995 15L8.59995 17.4L0.199951 9.00002L8.59995 0.600025L9.79995 3.00002Z"
      fill="#FFC641"
    />
  </svg>
);

export default GoBackIcon;
