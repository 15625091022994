import { SiderItemIconComponentProps } from '../../types/sider-items';

const UsersListIcon = ({ color }: SiderItemIconComponentProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3999 4.53345H1.59991C1.45272 4.53345 1.33325 4.65264 1.33325 4.8001V9.86677C1.33325 10.0142 1.45272 10.1334 1.59991 10.1334H14.3999C14.5471 10.1334 14.6666 10.0142 14.6666 9.86677V4.8001C14.6666 4.65264 14.5471 4.53345 14.3999 4.53345ZM14.1333 9.60012H1.86656V5.06676H14.1332V9.60012H14.1333Z"
      fill={color}
    />
    <path
      d="M6.85517 7.27816C6.75117 7.17388 6.58211 7.17388 6.47811 7.27816L5.41146 8.34482C5.30718 8.44907 5.30718 8.61763 5.41146 8.72188C5.46346 8.77388 5.53174 8.80001 5.59999 8.80001C5.66827 8.80001 5.73652 8.77388 5.78852 8.72188L6.85517 7.65523C6.95946 7.55098 6.95946 7.38244 6.85517 7.27816Z"
      fill={color}
    />
    <path
      d="M7.92186 8.34485L6.85517 7.27816C6.75117 7.17388 6.58211 7.17388 6.47811 7.27816C6.37383 7.38244 6.37383 7.55098 6.47811 7.65523L7.54477 8.72188C7.59677 8.77388 7.66505 8.80001 7.7333 8.80001C7.80158 8.80001 7.86983 8.77388 7.92183 8.72188C8.02611 8.61763 8.02611 8.4491 7.92186 8.34485Z"
      fill={color}
    />
    <path
      d="M8.45529 6.74471C8.35129 6.64043 8.18222 6.64043 8.07822 6.74471L7.01156 7.81136C6.90728 7.91561 6.90728 8.08417 7.01156 8.18842C7.06356 8.24042 7.13184 8.26655 7.20009 8.26655C7.26837 8.26655 7.33662 8.24042 7.38862 8.18842L8.45529 7.12177C8.55957 7.01749 8.55957 6.84896 8.45529 6.74471Z"
      fill={color}
    />
    <path
      d="M9.52191 7.81136L8.45525 6.74471C8.35125 6.64043 8.18219 6.64043 8.07819 6.74471C7.97394 6.84899 7.97394 7.01752 8.07819 7.12177L9.14485 8.18842C9.19685 8.24042 9.26513 8.26655 9.33338 8.26655C9.40166 8.26655 9.46991 8.24042 9.52191 8.18842C9.62616 8.08417 9.62616 7.91561 9.52191 7.81136Z"
      fill={color}
    />
    <path
      d="M14.8 0H1.2C0.538406 0 0 0.538406 0 1.2V14.8C0 15.4616 0.538406 16 1.2 16H14.8C15.4616 16 16 15.4616 16 14.8V1.2C16 0.538406 15.4616 0 14.8 0ZM15.4667 14.8C15.4667 15.1677 15.1677 15.4667 14.8 15.4667H1.2C0.832531 15.4667 0.533344 15.1677 0.533344 14.8V1.2C0.533344 0.83225 0.832531 0.533344 1.2 0.533344H14.8C15.1675 0.533344 15.4667 0.832281 15.4667 1.2V14.8Z"
      fill={color}
    />
    <path
      d="M2.39996 1.06665H1.33331C1.18612 1.06665 1.06665 1.18584 1.06665 1.33331V2.4C1.06665 2.54747 1.18612 2.66665 1.33331 2.66665H2.39996C2.54715 2.66665 2.66662 2.54747 2.66662 2.4V1.33334C2.66662 1.18587 2.54715 1.06665 2.39996 1.06665ZM2.1333 2.13334H1.59996V1.6H2.1333V2.13334Z"
      fill={color}
    />
    <path
      d="M10.6606 5.6001H10.1396C9.84197 5.6001 9.6001 5.84197 9.6001 6.13957V6.66091C9.6001 6.95825 9.84197 7.2001 10.1396 7.2001H10.6606C10.9582 7.2001 11.2001 6.95822 11.2001 6.66063V6.13929C11.2001 5.84197 10.9582 5.6001 10.6606 5.6001ZM10.6668 6.66091C10.6668 6.66385 10.6641 6.66679 10.6606 6.66679L10.1334 6.66091L10.1396 6.13344L10.6668 6.13957V6.66091Z"
      fill={color}
    />
    <path
      d="M14.8 0H1.2C0.538406 0 0 0.538406 0 1.2V3.46666C0 3.61413 0.119469 3.73331 0.266656 3.73331H15.7333C15.8805 3.73331 16 3.61413 16 3.46666V1.2C16 0.538406 15.4616 0 14.8 0ZM15.4667 3.2H0.533344V1.2C0.533344 0.83225 0.832531 0.533344 1.2 0.533344H14.8C15.1675 0.533344 15.4667 0.832281 15.4667 1.2V3.2Z"
      fill={color}
    />
    <path
      d="M12.8667 10.9333H3.26666C3.11947 10.9333 3 11.0525 3 11.2C3 11.3475 3.11947 11.4667 3.26666 11.4667H12.8667C13.0138 11.4667 13.1333 11.3475 13.1333 11.2C13.1333 11.0525 13.0138 10.9333 12.8667 10.9333Z"
      fill={color}
    />
    <path
      d="M12.8667 12H3.26666C3.11947 12 3 12.1192 3 12.2667C3 12.4141 3.11947 12.5333 3.26666 12.5333H12.8667C13.0138 12.5333 13.1333 12.4141 13.1333 12.2667C13.1333 12.1192 13.0138 12 12.8667 12Z"
      fill={color}
    />
    <path
      d="M12.8667 13.0667H3.26666C3.11947 13.0667 3 13.1858 3 13.3333C3 13.4808 3.11947 13.6 3.26666 13.6H12.8667C13.0138 13.6 13.1333 13.4808 13.1333 13.3333C13.1333 13.1859 13.0138 13.0667 12.8667 13.0667Z"
      fill={color}
    />
    <path
      d="M12.8667 14.1333H3.26666C3.11947 14.1333 3 14.2525 3 14.4C3 14.5474 3.11947 14.6666 3.26666 14.6666H12.8667C13.0138 14.6666 13.1333 14.5474 13.1333 14.4C13.1333 14.2525 13.0138 14.1333 12.8667 14.1333Z"
      fill={color}
    />
  </svg>
);

export default UsersListIcon;
