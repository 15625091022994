import styled from 'styled-components';

interface PaginationProps {
  previousPage: () => void;
  nextPage: () => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageIndex: number;
}

export const Pagination: React.FC<PaginationProps> = ({
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageIndex,
}) => {
  return (
    <Root>
      <button onClick={previousPage} disabled={!canPreviousPage}>
        {'<'}
      </button>{' '}
      <p>{pageIndex}</p>
      <button onClick={nextPage} disabled={!canNextPage}>
        {'>'}
      </button>{' '}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: center;
  height: 28px;
  margin-top: 30px;

  p {
    margin: 0 5px;
    line-height: 2;
  }

  button {
    cursor: pointer;
  }
`;
