const BathroomsIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.9375 7.47217H14.0625C14.5794 7.47217 15 7.89278 15 8.40967C15 8.92655 14.5794 9.34717 14.0625 9.34717H0.9375C0.420615 9.34717 0 8.92655 0 8.40967C0 7.89278 0.420615 7.47217 0.9375 7.47217ZM0.9375 8.72215H14.0625C14.2344 8.72215 14.375 8.58214 14.375 8.40964C14.375 8.23714 14.2344 8.09713 14.0625 8.09713H0.9375C0.765615 8.09713 0.62499 8.23714 0.62499 8.40964C0.62499 8.58214 0.765615 8.72215 0.9375 8.72215Z"
      fill="#979797"
    />
    <path
      d="M0.851076 8.73404C1.02044 8.68717 1.19045 8.78279 1.23733 8.94905L2.09857 11.9641C2.2892 12.6309 2.90731 13.0972 3.60106 13.0972H11.398C12.0917 13.0972 12.7092 12.6309 12.9004 11.9641L13.7617 8.94902C13.8086 8.78276 13.9792 8.68714 14.148 8.73401C14.3142 8.7815 14.4098 8.954 14.3623 9.12026L13.5011 12.1353C13.2342 13.0696 12.3698 13.7221 11.398 13.7221H3.6017C2.62984 13.7221 1.76482 13.0696 1.49795 12.1353L0.636711 9.12026C0.589191 8.95465 0.684816 8.78153 0.851076 8.73404Z"
      fill="#979797"
    />
    <path
      d="M10.798 13.1297C10.9524 13.054 11.1392 13.1147 11.2174 13.2697L11.8424 14.5197C11.9192 14.674 11.8568 14.8615 11.7024 14.9391C11.6567 14.9616 11.6092 14.9722 11.5623 14.9722C11.448 14.9722 11.338 14.9091 11.283 14.7991L10.658 13.5491C10.5811 13.3947 10.6436 13.2072 10.798 13.1297Z"
      fill="#979797"
    />
    <path
      d="M3.1575 14.5203L3.78249 13.2703C3.85998 13.1153 4.04748 13.0534 4.20187 13.1303C4.35624 13.2078 4.41876 13.3953 4.34188 13.5497L3.71689 14.7997C3.6619 14.909 3.5519 14.9722 3.43752 14.9722C3.39065 14.9722 3.34316 14.9615 3.29751 14.9397C3.14312 14.8622 3.08063 14.6747 3.1575 14.5203Z"
      fill="#979797"
    />
    <path
      d="M2.44686 0.027832C2.76561 0.027832 3.06561 0.152197 3.29123 0.377842L4.28311 1.36972C4.40498 1.49159 4.40498 1.68973 4.28311 1.8116C4.16123 1.93348 3.9631 1.93348 3.84122 1.8116L2.84937 0.820342C2.7425 0.712852 2.59938 0.653467 2.44376 0.653467C2.13002 0.653467 1.87502 0.908467 1.87502 1.22221V7.78471C1.87502 7.95721 1.73501 8.09722 1.56251 8.09722C1.39001 8.09722 1.25 7.95721 1.24938 7.78409V1.22159C1.24935 0.563467 1.78437 0.027832 2.44686 0.027832Z"
      fill="#979797"
    />
    <path
      d="M3.5282 1.68279L4.15319 1.0578C4.22505 0.985938 4.32443 0.952803 4.42506 0.970293L5.98755 1.22655C6.10254 1.24469 6.19694 1.32593 6.23379 1.43655C6.27065 1.54718 6.24255 1.66905 6.16128 1.75217L4.28628 3.68342C4.22754 3.74529 4.14566 3.77843 4.06254 3.77843C4.03316 3.77843 4.00316 3.77406 3.97316 3.76592C3.86192 3.73343 3.77754 3.64155 3.75504 3.52779L3.44253 1.9653C3.42194 1.86279 3.45443 1.75653 3.5282 1.68279ZM4.25256 2.82153L5.29694 1.74593L4.48257 1.6128L4.08944 2.00594L4.25256 2.82153Z"
      fill="#979797"
    />
    <path
      d="M7.59196 3.81412C7.71384 3.69225 7.91197 3.69225 8.03385 3.81412L8.65884 4.43911C8.78071 4.56099 8.78071 4.75912 8.65884 4.881C8.59758 4.94161 8.5176 4.97226 8.43759 4.97226C8.35758 4.97226 8.2776 4.94164 8.21695 4.881L7.59196 4.25601C7.47009 4.1341 7.47009 3.936 7.59196 3.81412Z"
      fill="#979797"
    />
    <path
      d="M6.34196 5.064C6.46384 4.94213 6.66197 4.94213 6.78385 5.064L7.40884 5.68899C7.53071 5.81087 7.53071 6.009 7.40884 6.13088C7.34758 6.19149 7.2676 6.22214 7.18759 6.22214C7.10758 6.22214 7.0276 6.19152 6.96695 6.13088L6.34196 5.50589C6.22009 5.38401 6.22009 5.18588 6.34196 5.064Z"
      fill="#979797"
    />
    <path
      d="M5.71696 3.18906C5.83884 3.06719 6.03697 3.06719 6.15885 3.18906L6.78384 3.81405C6.90571 3.93593 6.90571 4.13406 6.78384 4.25594C6.72258 4.31655 6.6426 4.3472 6.56259 4.3472C6.48258 4.3472 6.4026 4.31658 6.34195 4.25594L5.71696 3.63095C5.59509 3.50907 5.59509 3.31094 5.71696 3.18906Z"
      fill="#979797"
    />
  </svg>
);

export default BathroomsIcon;
