import styled from 'styled-components';

import { DeleteUploadIcon, EditUploadIcon } from '../../../../assets/icons';

interface ImageItemProps {
  src: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  deleteGalleryImage?: (src: string) => void;
}

const ImageItem: React.FC<ImageItemProps> = ({
  src,
  onChange,
  deleteGalleryImage,
}) => {
  return (
    <Root>
      <img alt="main preview" src={src} />
      {deleteGalleryImage ? (
        <IconWrapper onClick={() => deleteGalleryImage(src)}>
          <DeleteUploadIcon />
        </IconWrapper>
      ) : (
        <IconWrapper>
          <input
            type="file"
            name="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={onChange}
          />
          <EditUploadIcon />
        </IconWrapper>
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: ${(props) => props.theme.borderRadius.small};

  img {
    width: 150px;
    height: 150px;
    border-radius: inherit;
    object-fit: cover;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: ${(props) => props.theme.colors.color0};
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 0;
    opacity: 0;
    cursor: pointer;
  }
`;

export default ImageItem;
