import { Form } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import FormItem from '../../components/FormItem/index';
import Header from '../../components/Header';
import { isRoleExists } from '../../helpers/functions';
import { editUser } from '../../store/action-creators/user';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  InputText,
  Spinner,
  Text,
} from '../../ui';

const EditUserPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { colors } = useTheme();

  const { users, loading } = useTypedSelector((state) => state.user);
  const { id } = useParams<{ id: string }>();

  const editableUser = users.find((user) => user.id === +id);
  const [inputValues, setInputValues] = useState({
    firstName: editableUser?.firstName || '',
    lastName: editableUser?.lastName || '',
    email: editableUser?.email || '',
    phone: editableUser?.phone || '',
  });
  const [checkedList, setCheckedList] = useState([
    {
      id: 1,
      name: 'USER',
      label: 'User',
      checked: editableUser?.roles
        ? isRoleExists(editableUser.roles, 'USER')
        : false,
    },
    {
      id: 2,
      name: 'ADMIN',
      label: 'Administrator',
      checked: editableUser?.roles
        ? isRoleExists(editableUser.roles, 'ADMIN')
        : false,
    },
    {
      id: 3,
      name: 'MODERATOR',
      label: 'Moderator',
      checked: editableUser?.roles
        ? isRoleExists(editableUser.roles, 'MODERATOR')
        : false,
    },
    {
      id: 4,
      name: 'SUPPORT',
      label: 'Support',
      checked: editableUser?.roles
        ? isRoleExists(editableUser.roles, 'SUPPORT')
        : false,
    },
  ]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const handleCheckedList = (name: string) => {
    let roleList = [...checkedList];
    roleList.forEach((role) => {
      if (role.name === name) {
        role.checked = !role.checked;
      }
    });
    setCheckedList(roleList);
  };

  const handleEdit = async () => {
    const roles = checkedList
      .filter((el) => el.checked === true)
      .map((el) => el.id);
    await dispatch(editUser({ ...inputValues, roles }, +id));
    history.goBack();
  };

  return (
    <div>
      <Header goBackIcon={true} title={'Edit User'} />
      <FormWrapper>
        <Form
          name="basic"
          layout={'vertical'}
          onFinish={handleEdit}
          initialValues={inputValues}
        >
          <Text variant="title1bold">Information</Text>
          <InformationWrapper>
            <FormItem
              name={'firstName'}
              label={'First name'}
              rules={[
                {
                  required: true,
                  message: 'Incorrect first name!',
                  type: 'string',
                },
              ]}
            >
              <InputText
                value={inputValues.firstName}
                name={'firstName'}
                type={'text'}
                placeholder={'Your first name'}
                onChange={handleInput}
              />
            </FormItem>
            <FormItem
              name={'email'}
              label={'E-mail'}
              rules={[
                {
                  required: true,
                  message: 'Incorrect email!',
                  type: 'email',
                },
              ]}
            >
              <InputText
                value={inputValues.email}
                name={'email'}
                type={'text'}
                placeholder={'Your e-mail'}
                onChange={handleInput}
              />
            </FormItem>
            <FormItem
              name={'lastName'}
              label={'Last name'}
              rules={[
                {
                  required: true,
                  message: 'Incorrect last name!',
                  type: 'string',
                },
              ]}
            >
              <InputText
                value={inputValues.lastName}
                name={'lastName'}
                type={'text'}
                placeholder={'Your last name'}
                onChange={handleInput}
              />
            </FormItem>
            <FormItem
              name={'phone'}
              label={'Phone'}
              rules={[
                {
                  required: true,
                  message: 'Incorrect phone!',
                  type: 'string',
                },
              ]}
            >
              <InputText
                value={inputValues.phone}
                name={'phone'}
                type={'text'}
                placeholder={'Your phone'}
                onChange={handleInput}
              />
            </FormItem>
          </InformationWrapper>
          <Divider />
          <Text variant="title1bold">Select role</Text>
          <CheckboxesListWrapper>
            {checkedList.map((el: ICheckedList) => (
              <Flex key={el.id} flexDirection="row">
                <Checkbox
                  checked={el.checked}
                  color={colors.color0}
                  onClick={() => handleCheckedList(el.name)}
                />
                <Flex ml="10px">
                  <Text variant="body2">{el.label}</Text>
                </Flex>
              </Flex>
            ))}
          </CheckboxesListWrapper>
          <Flex flexDirection="row" m="50px 0px">
            <Button
              width="152px"
              variant="primary"
              text={'SAVE CHANGES'}
              disabled={loading}
            >
              <Spinner isRequesting={loading} variant={'small'} />
            </Button>
          </Flex>
        </Form>
      </FormWrapper>
    </div>
  );
};

interface ICheckedList {
  id: number;
  name: string;
  label: string;
  checked: boolean;
}

const FormWrapper = styled.div`
  width: 100%;
  height: auto;
  background: ${(props) => props.theme.colors.color1};
  border-radius: ${(props) => props.theme.borderRadius.big};
  padding: 30px 40px;
  margin: 30px auto;
`;
const InformationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10%;
  width: 90%;
  margin-top: 15px;
`;
const CheckboxesListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  margin-top: 30px;
`;

export default EditUserPage;
