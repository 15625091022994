import { InputNumber as AntInputNumber } from 'antd';
import styled from 'styled-components';

interface InputProps {
  value?: number | string;
  onChange?: (value: string | number) => void;
  disabled?: boolean;
  defaultValue?: number;
  placeholder?: string;
  name?: string;
}

const InputNumber: React.FC<InputProps> = ({ ...props }) => (
  <StyledInputNumber {...props} />
);

const StyledInputNumber = styled(AntInputNumber)`
  height: 51px;
  width: 100%;
  border-radius: ${(props) => props.theme.borderRadius.big};
  border: 1px solid ${(props) => props.theme.colors.color4};
  color: ${(props) => props.theme.colors.color2};

  .ant-input-number-input {
    height: 51px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export default InputNumber;
