const RefreshDataIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M15.4288 0C15.0737 0 14.7859 0.287816 14.7859 0.642869V2.9912C11.4762 -0.213047 6.19567 -0.127542 2.99142 3.1821C1.48133 4.74187 0.638901 6.82898 0.643082 8.99998C0.643082 9.35503 0.930898 9.64285 1.28595 9.64285C1.641 9.64285 1.92878 9.35503 1.92878 8.99998C1.92957 5.09455 5.09616 1.92917 9.00162 1.92996C10.9851 1.93034 12.8771 2.76373 14.2164 4.22677L11.3685 5.17625C11.0313 5.28846 10.8488 5.65282 10.961 5.99009C11.0732 6.32737 11.4375 6.50987 11.7748 6.39765L15.632 5.11195C15.8952 5.02426 16.0725 4.77747 16.0717 4.49997V0.642832C16.0716 0.287816 15.7838 0 15.4288 0Z"
        fill="#979797"
      />
      <path
        d="M16.7145 8.35718C16.3594 8.35718 16.0716 8.64499 16.0716 9.00005C16.0708 12.9055 12.9042 16.0709 8.99877 16.0701C7.01532 16.0697 5.12325 15.2363 3.78402 13.7733L6.63186 12.8238C6.96914 12.7116 7.15164 12.3472 7.03942 12.0099C6.92721 11.6727 6.56286 11.4902 6.22558 11.6024L2.36844 12.8881C2.10515 12.9758 1.92785 13.2226 1.92871 13.5001V17.3572C1.92871 17.7122 2.21653 18.0001 2.57158 18.0001C2.92664 18.0001 3.21445 17.7122 3.21445 17.3572V15.0088C6.52414 18.2131 11.8047 18.1276 15.0089 14.8179C16.519 13.2582 17.3615 11.171 17.3573 9.00005C17.3574 8.64499 17.0695 8.35718 16.7145 8.35718Z"
        fill="#979797"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RefreshDataIcon;
