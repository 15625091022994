import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

import Button from '../../../ui/Button';
import Flex from '../../../ui/Flex';
import Text from '../../../ui/Text';

const AreYouSureModal: React.FC<AreYouSureModalProps> = ({
  isVisible,
  onCancel,
  onSuccess,
}) => {
  return (
    <StyledModal visible={isVisible} onCancel={onCancel} footer={null}>
      <Text variant="title1bold" textAlign="center">
        Are you sure?
      </Text>
      <Flex flexDirection="row" mt="30px">
        <Flex mr="5px">
          <Button
            width="185px"
            variant="primary"
            text={'YES'}
            onClick={onSuccess}
          />
        </Flex>
        <Flex ml="5px">
          <Button
            width="185px"
            variant="secondary"
            text={'NO'}
            onClick={onCancel}
          />
        </Flex>
      </Flex>
    </StyledModal>
  );
};

interface AreYouSureModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 440px;
  }
  .ant-modal-body {
    padding: 30px;
  }
`;

export default AreYouSureModal;
