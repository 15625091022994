import { Dispatch } from 'redux';

import { REACT_APP_API_URL } from '../../constants/api';
import { handleResponseErrorAlert } from '../../helpers/functions';
import { AuthAction, AuthActionTypes } from '../types/auth';

export const signIn = (email: string, password: string) => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      dispatch({ type: AuthActionTypes.AUTH_ONGOING });
      const response = await fetch(`${REACT_APP_API_URL}/sign-in`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      if (response.status === 200) {
        const payload = await response.json();
        dispatch({ type: AuthActionTypes.AUTH_SUCCESS, payload });
      } else {
        dispatch({
          type: AuthActionTypes.AUTH_ERROR,
          payload: response.statusText + ' ' + response.status,
        });
        handleResponseErrorAlert(response.status);
      }
    } catch (e) {
      dispatch({
        type: AuthActionTypes.AUTH_ERROR,
        payload: 'auth error',
      });
    }
  };
};
