import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { GoBackIcon } from '../../assets/icons';
import { AuthActionTypes } from '../../store/types/auth';
import Button from '../../ui/Button';
import Search from './Search';
import SupportInfo from './SupportInfo';

interface HeaderProps {
  goBackIcon: boolean;
  title: string;
}

const Header: React.FC<HeaderProps> = ({ goBackIcon, title }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch({ type: AuthActionTypes.AUTH_LOGOUT });
  };

  return (
    <Root>
      <IconWrapper goBackIcon={goBackIcon} onClick={() => history.goBack()}>
        <GoBackIcon />
      </IconWrapper>
      <Title>{title}</Title>
      <Search />
      <SupportInfo />
      <div>
        <Button
          width="152px"
          variant="secondary"
          text={'LOG OUT'}
          disabled={false}
          onClick={handleLogout}
        />
      </div>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  height: 51px;
`;

interface GoBackIconProps {
  goBackIcon: boolean;
}

const IconWrapper = styled.div<GoBackIconProps>`
  display: ${(props) => (props.goBackIcon ? 'flex' : 'none')};
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.pageTitle};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  white-space: nowrap;
  margin: 0;
  padding: 0;
  margin-right: 30px;
`;

export default Header;
