import React from 'react';
import styled from 'styled-components';
import {
  FlexboxProps,
  GridProps,
  LayoutProps,
  SpaceProps,
  compose,
  flexbox,
  grid,
  layout,
  space,
} from 'styled-system';

const Flex: React.FC<FlexProps> = ({ children, ...props }) => (
  <Root {...props}>{children}</Root>
);

export interface FlexProps
  extends FlexboxProps,
    GridProps,
    SpaceProps,
    LayoutProps {
  cursor?: string;
  onClick?: () => void;
}

interface RootProps {
  cursor?: string;
}

const Root = styled.div<RootProps>`
  display: flex;
  flex-direction: column;
  cursor: ${(props) => props.cursor};
  ${compose(flexbox, grid, space, layout)};
`;

export default Flex;
