import { Layout } from 'antd';
import { Switch } from 'react-router-dom';
import styled from 'styled-components';

import Sider from '../components/Sider';
import AddAdPage from '../pages/Ad/AddAd';
import AdsListPage from '../pages/Ad/AdsList';
import EditAdPage from '../pages/Ad/EditAd';
import DocumentsList from '../pages/Documents/DocumentsList';
import AddUserPage from '../pages/User/AddUser';
import EditUserPage from '../pages/User/EditUser';
import UsersListPage from '../pages/User/UsersList';
import AuthRoute from './AuthRoute';
import {
  PAGE_ADD_AD_PATH,
  PAGE_ADD_USER_PATH,
  PAGE_ADS_PATH,
  PAGE_DOCUMENTS_PATH,
  PAGE_EDIT_AD_PATH,
  PAGE_EDIT_USER_PATH,
  PAGE_HOME_PATH,
  PAGE_SUPPORT_PATH,
  PAGE_USERS_PATH,
} from './constants';

const { Content } = Layout;

const AdminPanel: React.FC = () => {
  return (
    <Layout>
      <Sider />
      <StyledContent>
        <Switch>
          <AuthRoute exact path={PAGE_HOME_PATH} type="private">
            <div>home</div>
          </AuthRoute>
          <AuthRoute exact path={PAGE_USERS_PATH} type="private">
            <UsersListPage />
          </AuthRoute>
          <AuthRoute exact path={PAGE_ADD_USER_PATH} type="private">
            <AddUserPage />
          </AuthRoute>
          <AuthRoute exact path={PAGE_EDIT_USER_PATH} type="private">
            <EditUserPage />
          </AuthRoute>
          <AuthRoute exact path={PAGE_DOCUMENTS_PATH} type="private">
            <DocumentsList />
          </AuthRoute>
          <AuthRoute exact path={PAGE_ADS_PATH} type="private">
            <AdsListPage />
          </AuthRoute>
          <AuthRoute exact path={PAGE_ADD_AD_PATH} type="private">
            <AddAdPage />
          </AuthRoute>
          <AuthRoute exact path={PAGE_EDIT_AD_PATH} type="private">
            <EditAdPage />
          </AuthRoute>
          <AuthRoute exact path={PAGE_SUPPORT_PATH} type="private">
            <div>support</div>
          </AuthRoute>
        </Switch>
      </StyledContent>
    </Layout>
  );
};

const StyledContent = styled(Content)`
  padding: 30px;
  background: ${(props) => props.theme.colors.color5};
`;

export default AdminPanel;
