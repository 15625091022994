const EditUserIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5331 1.8254L13.1738 0.466031C12.5527 -0.155033 11.5423 -0.155003 10.9212 0.466031C10.3365 1.05082 1.40519 9.98281 0.808326 10.5797C0.744752 10.6433 0.702154 10.7278 0.686979 10.8104L0.00735101 14.4807C-0.0189868 14.623 0.0263646 14.7692 0.128698 14.8715C0.231149 14.974 0.377341 15.0192 0.519459 14.9929L4.18944 14.3132C4.27426 14.2974 4.35764 14.2543 4.42016 14.1918L14.5331 4.07812C15.1556 3.45562 15.1557 2.44799 14.5331 1.8254ZM0.987916 14.0122L1.39904 11.792L3.208 13.6011L0.987916 14.0122ZM4.10943 13.2595L1.74052 10.8905L10.3892 2.2411L12.7581 4.61018L4.10943 13.2595ZM13.9116 3.45665L13.3796 3.98871L11.0107 1.61962L11.5427 1.08756C11.821 0.809184 12.2739 0.809155 12.5523 1.08756L13.9116 2.44694C14.1906 2.72599 14.1906 3.17757 13.9116 3.45665Z"
      fill="#FFC641"
    />
  </svg>
);

export default EditUserIcon;
