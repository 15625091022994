import styled from 'styled-components';

import support_img from '../../assets/img/support_pic.jpg';

const SupportInfo = () => {
  return (
    <Root>
      <SupportPic>
        <img alt="support" src={support_img} />
      </SupportPic>
      <SupportName>Name Support</SupportName>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
`;

const SupportPic = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border: 1px solid grey;
  border-radius: 100%;
  margin-right: 15px;

  img {
    border-radius: inherit;
  }
`;

const SupportName = styled.p`
  color: ${(props) => props.theme.colors.color2};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  white-space: nowrap;
  padding: 0;
  margin: 0;
`;

export default SupportInfo;
