import { Input } from 'antd';
import styled from 'styled-components';

interface TextAreaProps {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string;
}

const TextArea: React.FC<TextAreaProps> = ({ ...props }) => {
  return <StyledTextArea {...props} autoSize={{ minRows: 7 }} />;
};

const StyledTextArea = styled(Input.TextArea)`
  border-radius: ${(props) => props.theme.borderRadius.big};
`;

export default TextArea;
