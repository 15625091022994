const DeleteUploadIcon = () => (
  <svg
    width="6"
    height="6"
    viewBox="0 0 6 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.32909 0.083374L2.99992 2.41254L0.670752 0.083374L0.083252 0.670874L2.41242 3.00004L0.083252 5.32921L0.670752 5.91671L2.99992 3.58754L5.32909 5.91671L5.91659 5.32921L3.58742 3.00004L5.91659 0.670874L5.32909 0.083374Z"
      fill="#121212"
    />
  </svg>
);

export default DeleteUploadIcon;
