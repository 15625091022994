import { IUsers } from './user';

export interface AuthState {
  isAuthUser: boolean;
  loading: boolean;
  error: null | string;
}

export enum AuthActionTypes {
  AUTH_ONGOING = 'AUTH_ONGOING',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
  AUTH_ERROR = 'AUTH_ERROR',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
}

interface AuthOngoingAction {
  type: AuthActionTypes.AUTH_ONGOING;
}

interface AuthSuccessAction {
  type: AuthActionTypes.AUTH_SUCCESS;
  payload: {
    token: string;
    user: IUsers;
  };
}

interface AuthErrorAction {
  type: AuthActionTypes.AUTH_ERROR;
  payload: string;
}

interface AuthLogoutAction {
  type: AuthActionTypes.AUTH_LOGOUT;
}

export type AuthAction =
  | AuthOngoingAction
  | AuthSuccessAction
  | AuthErrorAction
  | AuthLogoutAction;
