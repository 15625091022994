export const lightTheme = {
  name: 'light',
  borderRadius: {
    big: '10px',
    small: '5px',
  },
  colors: {
    color0: '#FFC641', // Yellow Dark
    color1: '#FFFFFF', // White
    color2: '#121212', // Black
    color3: '#979797', // Grey
    color4: '#E0E0E0', // Grey Light
    color5: '#E5E5E5', // Grey Background
    color6: '#C4C4C4', // Grey Switch
    color7: '#B11733', // Red
    color8: '#62D490', // Green
  },
  fontSize: {
    small: '12px',
    semiMedium: '14px',
    medium: '16px',
    smallTitle: '24px',
    pageTitle: '36px',
  },
  fontWeight: {
    normal: '400',
    bold: '600',
  },
  lineHeight: {
    small: '15px',
    semiMedium: '18px',
    medium: '21px',
  },
  typography: {
    title0bold: `
      font-weight: 600;
      font-style: normal;
      font-size: 36px;
      line-height: 48px;
      color: #121212;
    `,
    title0: `
      font-weight: 400;
      font-style: normal;
      font-size: 36px;
      line-height: 48px;
      color: #121212;
    `,
    title1: `
      font-weight: 400;
      font-style: normal;
      font-size: 24px;
      line-height: 32px;
      color: #121212;
    `,
    title1bold: `
      font-weight: 600;
      font-style: normal;
      font-size: 24px;
      line-height: 32px;
      color: #121212;
    `,
    body1: `
      font-weight: 400;
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      color: #121212;
    `,
    body1bold: `
      font-weight: 600;
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      color: #121212;
    `,
    body2: `
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      line-height: 21px;
      color: #121212;
    `,
    body2bold: `
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      line-height: 21px;
      color: #121212;
    `,
    body3: `
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      color: #121212;
    `,
    body3bold: `
      font-weight: 600;
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      color: #121212;
    `,
    footnote1: `
      font-weight: 400;
      font-style: normal;
      font-size: 12px;
      line-height: 16px;
      color: #121212;
    `,
    footnote1Bold: `
      font-weight: 600;
      font-style: normal;
      font-size: 12px;
      line-height: 16px;
      color: #121212;
    `,
  },
};
