import styled from 'styled-components';

interface CustomButtonProps {
  width: string;
  variant: 'primary' | 'secondary';
  text: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  children?: React.ReactChild;
}

const Button: React.FC<CustomButtonProps> = ({
  width,
  variant,
  text,
  onClick,
  disabled,
  children,
}) => {
  return (
    <Root
      width={width}
      className={variant}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      {text}
    </Root>
  );
};

interface RootProps {
  width: string;
}

const Root = styled.button<RootProps>`
  width: ${(props) => props.width};
  height: 51px;
  color: ${(props) => props.theme.colors.color2};
  background: none;
  padding: 0;
  border-width: 0;
  border-radius: ${(props) => props.theme.borderRadius.big};
  font-size: ${(props) => props.theme.fontSize.medium};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  cursor: pointer;

  &.primary {
    background: ${(props) => props.theme.colors.color0};
  }

  &.secondary {
    background: ${(props) => props.theme.colors.color1};
    border: 1px solid ${(props) => props.theme.colors.color0};
  }
`;

export default Button;
