import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { usePagination, useTable } from 'react-table';
import styled, { useTheme } from 'styled-components';

import { DeleteUserIcon, EditUserIcon } from '../../assets/icons';
import Header from '../../components/Header';
import { Pagination } from '../../components/Pagination';
import { isRoleExists } from '../../helpers/functions';
import { fetchUsers } from '../../store/action-creators/user';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import { IUsers } from '../../store/types/user';
import { Button, Checkbox, Flex, Spinner, Text } from '../../ui';

const UsersListPage: React.FC = () => {
  const history = useHistory();
  const { colors } = useTheme();
  const dispatch = useDispatch();
  const { users, totalPages, loading } = useTypedSelector(
    (state) => state.user,
  );
  const data: IUsers[] = React.useMemo(() => users, [users]);
  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Name',
        Cell: ({ row }: any) => (
          <Text variant="body2">
            {row.original.firstName} {row.original.lastName}
          </Text>
        ),
      },
      {
        Header: 'E-mail',
        Cell: ({ row }: any) => (
          <Text variant="body2">{row.original.email}</Text>
        ),
      },
      {
        Header: 'Phone',
        Cell: ({ row }: any) => (
          <Text variant="body2">{row.original.phone}</Text>
        ),
      },
      {
        id: 'administrator',
        Header: () => (
          <Text variant="body2bold" textAlign="center">
            Administrator
          </Text>
        ),
        Cell: ({ row }: any) => (
          <Flex alignItems="center">
            <Checkbox
              color={colors.color8}
              checked={isRoleExists(row.original.roles, 'ADMIN')}
            />
          </Flex>
        ),
      },
      {
        id: 'moderator',
        Header: () => (
          <Text variant="body2bold" textAlign="center">
            Moderator
          </Text>
        ),
        Cell: ({ row }: any) => (
          <Flex alignItems="center">
            <Checkbox
              color={colors.color8}
              checked={isRoleExists(row.original.roles, 'MODERATOR')}
            />
          </Flex>
        ),
      },
      {
        id: 'support',
        Header: () => (
          <Text variant="body2bold" textAlign="center">
            Support
          </Text>
        ),
        Cell: ({ row }: any) => (
          <Flex alignItems="center">
            <Checkbox
              color={colors.color8}
              checked={isRoleExists(row.original.roles, 'SUPPORT')}
            />
          </Flex>
        ),
      },
      {
        id: 'actions',
        Header: () => (
          <Text variant="body2bold" textAlign="center">
            Actions
          </Text>
        ),
        Cell: ({ row }: any) => (
          <Flex flexDirection="row" justifyContent="space-around">
            <Flex
              cursor="pointer"
              onClick={() => history.push(`/edit-user/${row.original.id}`)}
            >
              <EditUserIcon />
            </Flex>
            <Flex cursor="pointer">
              <DeleteUserIcon />
            </Flex>
          </Flex>
        ),
      },
    ],
    [colors.color8, history],
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 6 },
      manualPagination: true,
      pageCount: totalPages,
    },
    usePagination,
  );

  useEffect(() => {
    dispatch(fetchUsers(pageIndex));
  }, [dispatch, pageIndex]);

  return (
    <div>
      <Header goBackIcon={false} title={'List of users'} />
      <Flex flexDirection="row" justifyContent="flex-end" mt="30px">
        <Button
          width="152px"
          variant="primary"
          text={'+ ADD USER'}
          onClick={() => history.push('/add-user')}
        />
      </Flex>
      <ContentWrapper>
        <TableContainer>
          <SpinnerWrapper>
            <Spinner isRequesting={loading} variant={'big'} />
          </SpinnerWrapper>
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableHeaderCell {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </TableHeaderCell>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableDataCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableDataCell>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageIndex={pageIndex + 1}
          />
        </TableContainer>
      </ContentWrapper>
    </div>
  );
};

const ContentWrapper = styled.div`
  margin-top: 30px;
  padding: 30px;
  background: ${(props) => props.theme.colors.color1};
  border-radius: ${(props) => props.theme.borderRadius.big};
`;

const TableContainer = styled.div`
  position: relative;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  left: calc(50% - 18px);
  top: calc(50% - 18px);
`;

const Table = styled.table`
  width: 100%;
`;

const TableHeaderCell = styled.th`
  text-align: left;
  padding: 30px 0px;
  border-bottom: 1px solid ${(props) => props.theme.colors.color4};
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

const TableDataCell = styled.td`
  padding: 15px 0px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.normal};
`;

export default UsersListPage;
