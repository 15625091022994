import { Form } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import FormItem from '../../components/FormItem';
import Header from '../../components/Header';
import { simpleAdValidation } from '../../helpers/functions';
import {
  deleteAd,
  editAd,
  fetchNovelty,
  fetchPropertyTypes,
} from '../../store/action-creators/ad';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import { PreviewImage } from '../../types/ad';
import {
  Button,
  Divider,
  Flex,
  InputNumber,
  InputText,
  Select,
  Spinner,
  Switch,
  Text,
  TextArea,
} from '../../ui';
import Charactersitics from './components/Characteristics';
import GoogleMapLocation from './components/GoogleMapLocation';
import PhotoWall from './components/PhotoWall/index';

interface IAddress {
  country: string;
  city: string;
  streetAddress: string;
  latitude: number;
  longitude: number;
}

const EditAdPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    ads,
    loading,
    propertyTypes: propertyTypesOptions,
    novelty: noveltyOptions,
  } = useTypedSelector((state) => state.ad);
  const { id } = useParams<{ id: string }>();
  const editableAd = ads.find((ad) => ad.id === +id);
  const [locationInput, setLocationInput] = useState(
    (editableAd?.address + ', ' || '') +
      (editableAd?.city?.name + ', ' || '') +
      (editableAd?.country?.name || ''),
  );
  const [address, setAddress] = useState<IAddress>({
    country: editableAd?.country?.name || '',
    city: editableAd?.city?.name || '',
    streetAddress: editableAd?.address || '',
    latitude: editableAd?.latitude || 0,
    longitude: editableAd?.longitude || 0,
  });
  const [propertyType, setPropertyType] = useState(
    editableAd?.propertyType.name || '',
  );
  const [novelty, setNovelty] = useState(editableAd?.novelty.name || '');
  const [mainImage, setMainImage] = useState({
    uploadObject: new File(['preview'], editableAd?.preview.url || ''),
    src: editableAd?.preview.url || '',
  });
  const [galleryImages, setGalleryImages] = useState(
    editableAd?.images.map((image) => ({
      uploadObject: new File(['image'], image.url),
      src: image.url,
    })) || [],
  );
  const [name, setName] = useState(editableAd?.name || '');
  const [tourUrl, setTourUrl] = useState(editableAd?.tourUrl || '');
  const [price, setPrice] = useState(editableAd?.price || 0);
  const [bedrooms, setBedrooms] = useState(editableAd?.bedrooms || 0);
  const [bathrooms, setBathrooms] = useState(editableAd?.bathrooms || 0);
  const [floorSpace, setFloorSpace] = useState(editableAd?.floorSpace || 0);
  const [capacity, setCapacity] = useState(editableAd?.capacity || 0);
  const [description, setDescription] = useState(editableAd?.description || '');

  /** Since patch request collects all the data (for unknown reason), we have to use 2 useEffects
   * in order to reconstruct images if they weren't updated
   */
  useEffect(() => {
    const fetchedMainImage = async () => {
      if (editableAd && editableAd.preview) {
        let file = await fetch(editableAd.preview.url)
          .then((r) => r.blob())
          .then(
            (blobFile) =>
              new File([blobFile], 'image_main', { type: 'image/png' }),
          );
        setMainImage({
          uploadObject: file,
          src: editableAd.preview.url,
        });
      }
    };
    fetchedMainImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const imageConstructor = async (url: string) => {
    let file = await fetch(url)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], `image_${url}`, { type: 'image/png' }),
      );
    return {
      uploadObject: file,
      src: url,
    };
  };
  useEffect(() => {
    const fetchedGalery = async () => {
      if (editableAd && editableAd.images && editableAd.images.length !== 0) {
        const imageFiles: any[] = [];
        editableAd.images.map((image) =>
          imageFiles.push(imageConstructor(image.url)),
        );
        Promise.all(imageFiles).then((resolvedPromises) => {
          setGalleryImages(resolvedPromises);
        });
        return;
      }
    };
    fetchedGalery().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePropertyType = (value: SelectValue) => {
    if (typeof value === 'string') {
      setPropertyType(value);
    }
  };
  const handleNovelty = (value: SelectValue) => {
    if (typeof value === 'string') {
      setNovelty(value);
    }
  };
  const selectLocation = (address: IAddress, location: string) => {
    setLocationInput(location);
    setAddress(address);
  };
  const handleMainImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setMainImage({
        uploadObject: e.target.files[0],
        src: URL.createObjectURL(e.target.files[0]),
      });
      e.target.value = '';
    }
  };
  const handleGalleryImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newGaleryArr: PreviewImage[] = [
        ...galleryImages,
        {
          uploadObject: e.target.files[0],
          src: URL.createObjectURL(e.target.files[0]),
        },
      ];
      setGalleryImages(newGaleryArr);
      e.target.value = '';
    }
  };
  const deleteGalleryImage = (src: string) => {
    const newGaleryArr: PreviewImage[] = galleryImages.filter(
      (item: PreviewImage) => item.src !== src,
    );
    setGalleryImages(newGaleryArr);
  };
  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleTourUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTourUrl(e.target.value);
  };
  const handlePrice = (value: string | number) => {
    if (typeof value === 'number') setPrice(value);
  };
  const handleBedrooms = (value: string | number) => {
    if (typeof value === 'number') setBedrooms(value);
  };
  const handleBathrooms = (value: string | number) => {
    if (typeof value === 'number') setBathrooms(value);
  };
  const handleFloorSpace = (value: string | number) => {
    if (typeof value === 'number') setFloorSpace(value);
  };
  const handleCapacity = (value: string | number) => {
    if (typeof value === 'number') setCapacity(value);
  };
  const handleDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };
  const handleEdit = async () => {
    const editBody = {
      images: galleryImages,
      bedrooms,
      bathrooms,
      floorSpace,
      capacity,
      price,
      name,
      tourUrl,
      description,
      country: address.country,
      city: address.city,
      address: address.streetAddress,
      noveltyId: noveltyOptions.find((el) => el.name === novelty)?.id || 1,
      propertyTypeId:
        propertyTypesOptions.find((el) => el.name === propertyType)?.id || 1,
      latitude: address.latitude,
      longitude: address.longitude,
      preview: mainImage,
    };
    const isValid = simpleAdValidation(editBody);
    if (isValid) {
      await dispatch(editAd(editBody, +id));
      history.goBack();
    }
  };
  const handleDelete = async () => {
    await dispatch(deleteAd(+id));
    history.goBack();
  };

  useEffect(() => {
    dispatch(fetchPropertyTypes());
    dispatch(fetchNovelty());
  }, [dispatch]);

  if (loading) {
    return (
      <Flex width="100%" m="0 auto">
        <Text variant="title1bold">...Loading</Text>
      </Flex>
    );
  }

  if (!editableAd) {
    history.push('/ads');
  }

  return (
    <div>
      <Header goBackIcon={true} title={'Editing a declaration'} />
      <FormWrapper>
        <Form
          name="basic"
          layout={'vertical'}
          onFinish={handleEdit}
          initialValues={{
            propertyType,
            novelty,
            name,
            tourUrl,
            price,
            bedrooms,
            capacity,
            bathrooms,
            floorSpace,
            description,
          }}
        >
          <Flex display="grid" gridTemplateColumns="1fr 1fr">
            <Flex>
              <Flex width="80%" mb="30px">
                <Text variant="title1bold" mb="30px">
                  Location
                </Text>
                <GoogleMapLocation
                  searchValue={locationInput}
                  onMarkerPlaced={selectLocation}
                />
              </Flex>
              <Flex width="80%" mb="15px">
                <Text variant="title1bold" mb="30px">
                  Property type
                </Text>
                <FormItem
                  name="propertyType"
                  rules={[
                    {
                      required: true,
                      message: 'Incorrect property type!',
                      type: 'string',
                    },
                  ]}
                >
                  <Select
                    onChange={handlePropertyType}
                    notFoundContent="Empty options..."
                    options={propertyTypesOptions}
                  />
                </FormItem>
              </Flex>
              <Flex width="80%">
                <Text variant="title1bold" mb="30px">
                  Novelty
                </Text>
                <FormItem
                  name="novelty"
                  rules={[
                    {
                      required: true,
                      message: 'Incorrect novelty!',
                      type: 'string',
                    },
                  ]}
                >
                  <Select
                    onChange={handleNovelty}
                    notFoundContent="Empty options..."
                    options={noveltyOptions}
                  />
                </FormItem>
              </Flex>
            </Flex>
            <PhotoWall
              mainImageSrc={mainImage.src}
              handleMainImage={handleMainImage}
              galleryImages={galleryImages}
              handleGalleryImages={handleGalleryImages}
              deleteGalleryImage={deleteGalleryImage}
            />
          </Flex>
          <Divider />
          <Flex width="40%">
            <Text variant="title1bold" mb="30px">
              Name
            </Text>
            <FormItem
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Incorrect name!',
                  type: 'string',
                },
              ]}
            >
              <InputText
                value={name}
                type={'text'}
                placeholder={'Select a name'}
                onChange={handleName}
              />
            </FormItem>
          </Flex>
          <Divider />
          <Flex width="40%">
            <Text variant="title1bold" mb="30px">
              Tour url
            </Text>
            <FormItem
              name="tourUrl"
              rules={[
                {
                  required: false,
                  message: 'Incorrect tour url!',
                  type: 'string',
                },
              ]}
            >
              <InputText
                value={tourUrl}
                type={'text'}
                onChange={handleTourUrl}
              />
            </FormItem>
          </Flex>
          <Divider />
          <Flex width="40%">
            <Text variant="title1bold" mb="30px">
              Price
            </Text>
            <FormItem
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Incorrect price!',
                  type: 'number',
                },
              ]}
            >
              <InputNumber value={price} onChange={handlePrice} />
            </FormItem>
          </Flex>
          <Divider />
          <Charactersitics
            characteristics={[
              {
                id: 1,
                name: 'bedrooms',
                value: bedrooms,
                onChange: handleBedrooms,
              },
              {
                id: 2,
                name: 'capacity',
                value: capacity,
                onChange: handleCapacity,
              },
              {
                id: 3,
                name: 'bathrooms',
                value: bathrooms,
                onChange: handleBathrooms,
              },
              {
                id: 4,
                name: 'floorSpace',
                value: floorSpace,
                onChange: handleFloorSpace,
              },
            ]}
          />
          <Divider />
          <Flex width="40%">
            <Text variant="title1bold" mb="30px">
              Description of the object
            </Text>
            <FormItem
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Incorrect description!',
                  type: 'string',
                },
              ]}
            >
              <TextArea value={description} onChange={handleDescription} />
            </FormItem>
          </Flex>
          <Divider />
          <Flex flexDirection="row" mb="30px">
            <Text variant="title1bold" mr="30px">
              Adversting ad
            </Text>
            <Switch />
          </Flex>
          <Flex flexDirection="row" gridGap="10px">
            <Button
              width="205px"
              variant="primary"
              text={'SAVE AND PUBLISH'}
              disabled={loading}
            >
              <Spinner isRequesting={loading} variant={'small'} />
            </Button>
            <Button
              width="205px"
              variant="secondary"
              text={'DELETE AN AD'}
              disabled={loading}
              onClick={handleDelete}
            >
              <Spinner isRequesting={loading} variant={'small'} />
            </Button>
          </Flex>
        </Form>
      </FormWrapper>
    </div>
  );
};

const FormWrapper = styled.div`
  width: 100%;
  height: auto;
  background: ${(props) => props.theme.colors.color1};
  border-radius: ${(props) => props.theme.borderRadius.big};
  padding: 30px 40px;
  margin: 30px auto;
`;

export default EditAdPage;
