import { Form, Modal } from 'antd';
import styled from 'styled-components';

import FormItem from '../../../components/FormItem';
import { Flex, InputNumber, Text } from '../../../ui';

const BookingModal: React.FC<BookingModalProps> = ({
  isVisible,
  onCancel,
  booking,
}) => {
  return (
    <StyledModal visible={isVisible} onCancel={onCancel} footer={null}>
      <Text variant="title1bold">Payment Calculator</Text>
      <Flex mt="30px">
        <Form name="basic" layout={'vertical'}>
          <FormItem name={'downpayment'} label={'Down Payment'}>
            <InputNumber
              placeholder={booking.downpayment.toString() + '%'}
              disabled={true}
            />
          </FormItem>
          <FormItem name={'term'} label={'Term'}>
            <InputNumber
              placeholder={booking.term.toString() + ' years'}
              disabled={true}
            />
          </FormItem>
          <FormItem name={'age'} label={'Age'}>
            <InputNumber placeholder={booking.age.toString()} disabled={true} />
          </FormItem>
          <FormItem
            name={'annualIncomes'}
            label={'Annual Net Income After Taxes'}
          >
            <InputNumber
              placeholder={booking.annualIncomes.toString() + '$'}
              disabled={true}
            />
          </FormItem>
          <FormItem
            name={'otherIncomes'}
            label={'Other available Income (if applicable)'}
          >
            <InputNumber
              placeholder={
                booking.otherIncomes === 0
                  ? '-'
                  : booking.otherIncomes.toString() + '$'
              }
              disabled={true}
            />
          </FormItem>
        </Form>
        <Flex flexDirection="row" justifyContent="space-between" mt="20px">
          <Text variant="body2bold">Lease payment</Text>
          <Text variant="body2bold" color="color7">
            ${booking.leasePayment}/mo
          </Text>
        </Flex>
      </Flex>
    </StyledModal>
  );
};

interface Booking {
  downpayment: number;
  term: number;
  age: number;
  annualIncomes: number;
  otherIncomes: number;
  leasePayment: number;
}

interface BookingModalProps {
  isVisible: boolean;
  onCancel: () => void;
  booking: Booking;
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 440px;
  }
  .ant-modal-body {
    padding: 30px;
  }
`;

export default BookingModal;
