const FloorSpaceIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.688991 1.36863L13.6314 14.311C13.8849 14.5645 13.7051 15 13.346 15H0.40361C0.18071 15 0 14.8193 0 14.5964V1.65403C-2.67029e-05 1.29549 0.435093 1.11476 0.688991 1.36863ZM0.807194 14.1928H12.3716L10.3189 12.1401L9.75673 12.7023C9.67792 12.7811 9.57462 12.8205 9.47135 12.8205C9.11499 12.8205 8.93097 12.3865 9.18597 12.1315L9.74817 11.5693L8.59689 10.4181L8.03469 10.9803C7.95588 11.0591 7.85258 11.0985 7.74931 11.0985C7.39295 11.0985 7.20893 10.6644 7.46393 10.4094L8.02611 9.84727L6.87482 8.69599L6.31262 9.25819C6.23381 9.337 6.13051 9.3764 6.02724 9.3764C5.67088 9.3764 5.48686 8.94238 5.74186 8.68738L6.30404 8.12521L5.15275 6.97393L4.59055 7.53613C4.51174 7.61494 4.40844 7.65434 4.30517 7.65434C3.94881 7.65434 3.76479 7.22032 4.01979 6.96532L4.58197 6.40315L3.43069 5.25187L2.86848 5.81407C2.78967 5.89288 2.68637 5.93228 2.5831 5.93228C2.22674 5.93228 2.04272 5.49826 2.29773 5.24326L2.8599 4.68109L0.80722 2.62842L0.807194 14.1928Z"
      fill="#979797"
    />
  </svg>
);

export default FloorSpaceIcon;
