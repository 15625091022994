import { Form, Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

import FormItem from '../../../components/FormItem';
import { Flex, InputNumber, InputText, Text, TextArea } from '../../../ui';

const ApartmentModal: React.FC<ApartmentModalProps> = ({
  isVisible,
  onCancel,
  apartment,
}) => {
  return (
    <StyledModal visible={isVisible} onCancel={onCancel} footer={null}>
      <Text variant="title1bold">Apartment</Text>
      <Flex mt="30px">
        <Form name="basic" layout={'vertical'}>
          <FormItem name={'address'} label={'Address'}>
            <InputText placeholder={apartment.address} disabled={true} />
          </FormItem>
          <FormItem name={'name'} label={'Name'}>
            <InputText placeholder={apartment.name} disabled={true} />
          </FormItem>
          <FormItem name={'price'} label={'Price'}>
            <InputNumber
              placeholder={apartment.price.toString()}
              disabled={true}
            />
          </FormItem>
          <FormItem name={'bathrooms'} label={'Bathrooms'}>
            <InputNumber
              placeholder={apartment.bathrooms.toString()}
              disabled={true}
            />
          </FormItem>
          <FormItem name={'bedrooms'} label={'Bedrooms'}>
            <InputNumber
              placeholder={apartment.bedrooms.toString()}
              disabled={true}
            />
          </FormItem>
          <FormItem name={'capacity'} label={'Capacity'}>
            <InputNumber
              placeholder={apartment.capacity.toString()}
              disabled={true}
            />
          </FormItem>
          <FormItem name={'floorSpace'} label={'Floor Space'}>
            <InputNumber
              placeholder={apartment.floorSpace.toString()}
              disabled={true}
            />
          </FormItem>
          <FormItem name={'description'} label={'Description'}>
            <TextArea
              placeholder={apartment.description.toString()}
              disabled={true}
            />
          </FormItem>
        </Form>
      </Flex>
    </StyledModal>
  );
};

interface Apartment {
  address: string;
  name: string;
  price: number;
  bathrooms: number;
  bedrooms: number;
  capacity: number;
  floorSpace: number;
  description: string;
}

interface ApartmentModalProps {
  isVisible: boolean;
  onCancel: () => void;
  apartment: Apartment;
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 440px;
  }
  .ant-modal-body {
    padding: 30px;
  }
`;

export default ApartmentModal;
