import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import {
  MutableRefObject,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FC } from 'react';

import { GOOGLE_API_KEY, GOOGLE_MAP_API_URL } from '../../../constants/api';
import { getAddressFromLocationObject } from '../../../helpers/functions';

const mapContainerStyle = {
  display: 'flex',
  height: '30vh',
  marginTop: '20px',
  borderRadius: '10px',
  border: '1px solid #E0E0E0',
  overflow: 'hidden',
};

const searchInputStyle = {
  border: '1px solid #d9d9d9',
  borderRadius: '10px',
  padding: '10px 20px',
  marginBottom: '10px',
  width: '100%',
};

const center = {
  lat: 40.71051763545055,
  lng: -74.0060459128175,
};

interface IAddress {
  country: string;
  city: string;
  streetAddress: string;
  latitude: number;
  longitude: number;
}

interface IProps {
  searchValue: string;
  onMarkerPlaced: (address: IAddress, locationInput: string) => void;
}

const GoogleMapLocation: FC<IProps> = (props: IProps) => {
  const { searchValue, onMarkerPlaced } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${GOOGLE_API_KEY}`,
  });
  const searchInputRef = useRef() as MutableRefObject<any>;
  const mapRef = useRef() as MutableRefObject<any>;
  const autoComplete = new window.google.maps.places.Autocomplete(
    searchInputRef.current!,
  );
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState<google.maps.LatLngLiteral>(
    {} as google.maps.LatLngLiteral,
  );

  const getAddressByLatLng = async ({
    lat,
    lng,
  }: {
    lat: google.maps.LatLngLiteral;
    lng: google.maps.LatLngLiteral;
  }) => {
    // const response = await fetch(`https://api.unsplash.com/photos/random?client_id=${process.env.NEXT_PUBLIC_UNSPLASH_API_ACCESS_KEY}`);
    const response = await fetch(
      `${GOOGLE_MAP_API_URL}?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`,
    );
    const data = await response.json();
    if (
      data.results[0] &&
      data.results[0].formatted_address &&
      data.results[0].address_components.length > 1
    ) {
      return data.results[0];
    } else {
      console.error('This location not available for selection');
      console.log('Location marker has no address to rely on');
      return;
    }
  };

  const getCoordsByAddress = async (addr: string) => {
    let preparedAddr = addr.replace(/,/g, '').replace(/ /g, '+');
    const response = await fetch(
      `${GOOGLE_MAP_API_URL}?address=${preparedAddr}&key=${GOOGLE_API_KEY}`,
    );
    const data = await response.json();

    return data.results[0].geometry.location;
  };

  autoComplete.addListener('place_changed', () => {
    const place = autoComplete.getPlace();
    if (!place.geometry || !place.geometry.location) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      console.error('This location not available');
      console.log('Location is invalid and has no geometry to rely on');
      return;
    }
    if (
      place.types &&
      (place.types.includes('street_address') ||
        place.types.includes('premise'))
    ) {
      if (place.geometry && place.geometry.location) {
        const coords = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        const address = getAddressFromLocationObject(place);
        onMarkerPlaced(
          {
            ...address,
            latitude: coords.lat,
            longitude: coords.lng,
          },
          searchInputRef.current.value,
        );
        setMarker(coords);
      }
    } else {
      console.error('This location not available');
      console.log('Location has no street address or premise');
      searchInputRef.current.value = '';
      return;
    }
  });

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setZoom(17);
      mapRef.current.panTo(marker);
    }
  }, [mapRef, marker]);

  const onMapLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    mapRef.current = map;
    setMap(map);
    if (searchValue) {
      searchInputRef.current.value = searchValue;
      getCoordsByAddress(searchValue)
        .then((coords) => setMarker(coords))
        .catch((error) => console.error('Error while fetching address', error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onMapClick = useCallback((e) => {
    const coords = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    getAddressByLatLng(coords)
      .then((data) => {
        const address = getAddressFromLocationObject(data);
        onMarkerPlaced(
          {
            ...address,
            latitude: coords.lat,
            longitude: coords.lng,
          },
          searchInputRef.current.value,
        );
      })
      .catch((error) => console.error('Error while fetching address', error));
    setMarker(coords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onMapUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  // onAddressUpdate()

  return isLoaded ? (
    <div>
      <input
        placeholder="Search for location"
        ref={searchInputRef}
        style={searchInputStyle}
        // style={searchInputStyle}
      />
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={10}
        onLoad={onMapLoad}
        onClick={onMapClick}
        onUnmount={onMapUnmount}
      >
        <Marker position={{ lat: marker.lat, lng: marker.lng }} />
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

export default memo(GoogleMapLocation);
