export const AUTH_TOKEN_KEY = 'auth_token';

class StorageClient {
  setObject = async (key: string, value: object) => {
    await localStorage.setItem(key, JSON.stringify(value));
  };

  getObject = async (key: string): Promise<any> => {
    const value = await localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  };

  async getAuthToken(): Promise<string | null> {
    return localStorage.getItem(AUTH_TOKEN_KEY);
  }

  setAuthToken = async (token: string) => {
    return localStorage.setItem(AUTH_TOKEN_KEY, token);
  };

  async removeToken() {
    const token = await this.getAuthToken();
    if (token) {
      await localStorage.removeItem(AUTH_TOKEN_KEY);
    }
  }
}

export const client = new StorageClient();
