const UploadIcon = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.40771 7.68262C5.41868 7.69662 5.43268 7.70795 5.44867 7.71574C5.46466 7.72353 5.48221 7.72758 5.5 7.72758C5.51779 7.72758 5.53534 7.72353 5.55133 7.71574C5.56732 7.70795 5.58132 7.69662 5.59229 7.68262L7.23291 5.60693C7.29297 5.53076 7.23877 5.41797 7.14062 5.41797H6.05518V0.460938C6.05518 0.396484 6.00244 0.34375 5.93799 0.34375H5.05908C4.99463 0.34375 4.94189 0.396484 4.94189 0.460938V5.4165H3.85938C3.76123 5.4165 3.70703 5.5293 3.76709 5.60547L5.40771 7.68262ZM10.8613 7.16992H9.98242C9.91797 7.16992 9.86523 7.22266 9.86523 7.28711V9.54297H1.13477V7.28711C1.13477 7.22266 1.08203 7.16992 1.01758 7.16992H0.138672C0.0742188 7.16992 0.0214844 7.22266 0.0214844 7.28711V10.1875C0.0214844 10.4468 0.230957 10.6562 0.490234 10.6562H10.5098C10.769 10.6562 10.9785 10.4468 10.9785 10.1875V7.28711C10.9785 7.22266 10.9258 7.16992 10.8613 7.16992Z"
      fill="#FFC641"
    />
  </svg>
);

export default UploadIcon;
