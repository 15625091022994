const RightArrowIcon = () => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-1.47821e-06 12L5 7L-1.0411e-06 2L0.999999 -3.0598e-07L8 7L0.999998 14L-1.47821e-06 12Z"
      fill="#FFC641"
    />
  </svg>
);

export default RightArrowIcon;
