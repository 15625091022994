import { Form } from 'antd';
import styled from 'styled-components';

interface FormItemRule {
  required?: boolean;
  message: string;
  type: 'string' | 'number' | 'boolean' | 'url' | 'email';
  min?: number;
}

interface IFormItem {
  name?: string;
  rules?: FormItemRule[];
  label?: string;
}

const FormItem: React.FC<IFormItem> = ({ children, ...props }) => (
  <StyledFormItem {...props}>{children}</StyledFormItem>
);

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 15px;

  label {
    color: ${(props) => props.theme.colors.color3};
    font-size: ${(props) => props.theme.fontSize.small};
    text-transform: capitalize;
  }

  .ant-form-item-control-input {
    display: block;
  }

  .ant-form-item-label {
    padding: 0;
  }
`;

export default FormItem;
